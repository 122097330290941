import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxTextField, StatusCell } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { editCommission } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const EditCommissionForm = ({
  commission,
  handleSubmit,
  toggleEditCommissionModal,
  submitting,
  pristine,
  invalid,
  error,
  theme,
  dispatch,
}) => {
  const submit = handleSubmit(values => editCommission({ ...values, commissionId: commission.id }, dispatch));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <form className="EditCommissionForm" onSubmit={submit}>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="justPartnerName">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>{commission.partnerName}</span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="boPartnerAccountNumber">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>{commission.account}</span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="boPartnerIbProgramm">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>{commission.ibProgramm}</span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="justDate">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>{commission.date}</span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="boRewardStatus">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <StatusCell statusCode={commission.status} />
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="justCalculatedReward">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>
          {commission.calculatedAmount.amount} {commission.calculatedAmount.currency}
        </span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="justCurrentReward">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <span>
          {commission.availableAmount.amount} {commission.availableAmount.currency}
        </span>
      </div>
      <div className="EditCommissionForm__line">
        <FormattedMessage id="newRewardAmount">
          {txt => <span className="EditCommissionForm__title">{txt}</span>}
        </FormattedMessage>
        <Field component={ReduxTextField} type="text" name="amount" disabled={submitting} />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <div className="EditCommissionForm__line">
        <DefaultButton
          type="submit"
          textId="justSave"
          loading={submitting}
          disabled={invalid || submitting || pristine}
          filled
        />
        <DefaultButton textId="justCancel" onClick={toggleEditCommissionModal} />
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

EditCommissionForm.propTypes = {
  commission: PropTypes.object.isRequired,
  toggleEditCommissionModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

EditCommissionForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  reduxForm({
    form: 'EditCommissionForm',
    onSubmitSuccess(result, dispatch, props) {
      props.toggleEditCommissionModal();
      props.getList();
    },
  })
)(EditCommissionForm);
export { EditCommissionForm };
