import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserInfoPanel {
    width: 100%;
  }

  .UserInfoPanel__inform {
    width: 100% !important;
    margin-top: 30px;
  }

  .UserInfoPanel__inform-block {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);
    padding: rem(25px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2,
    h3 {
      margin-bottom: rem(25px);
      width: 100%;
    }
  }

  .UserInfoPanel__inform-block_text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .UserInfoPanel__gravatar-inner {
    :global(.react-gravatar) {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 145px !important;
        height: 145px !important;
      }
      @include respond-to(sm) {
        display: block;
        width: 120px !important;
        height: 120px !important;
        margin: 0 auto;
      }
    }
  }

  .PersonalInfo-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .UserInfoPanel__inform-block_text {
      width: 70%;
      @include respond-to(sm) {
        width: 100%;
      }
      > div {
        width: 50%;
        @include respond-to(sm) {
          width: 100%;
        }
      }
    }

    .UserInfoPanel__inform-block_utm {
      width: 100% !important;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include respond-to(sm) {
        gap: 14px;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 50%;
        @include respond-to(sm) {
          width: 100%;
        }
      }
    }
  }

  .UserInfoPanel__gravatar-inner {
    width: 25%;
    @include respond-to(sm) {
      width: 100%;
      text-align: center;
    }
  }

  .UserInfoPanel__no-wrap {
    white-space: nowrap;

    .StatusCell {
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .InfoLine {
    display: flex;
    gap: 38px;
    @include respond-to(sm) {
      gap: 12px;
    }

    > span {
      width: 78px;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: #777777;
    }
    .InfoLine__right {
      > span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .InfoLine__edit {
    .InfoLine__right {
      white-space: nowrap;
      font-size: 13px;
      line-height: 18px;
      color: #ffffff;
    }
  }

  :global(.StatusPlate) {
    display: inline-flex;

    .StatusPlate__indicator {
      justify-content: center;
      margin: 3px 0;
    }

    .StatusPlate__line {
      width: 2px !important;
    }

    .StatusPlate__name {
      max-width: 90px !important;
      width: 100% !important;
      text-overflow: ellipsis;
      overflow: hidden;

      @include respond-to(lg) {
        max-width: 45px !important;
      }
    }
  }

  .PersonalInfo {
    display: block;
    position: relative;
    margin: 0 0 20px 0;
    padding: 25px 25px 25px 25px;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__title {
      &--hide {
        margin: 0 0 0 0 !important;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;

      @include respond-to(lg) {
        display: block;
      }

      &--hide {
        display: none;
      }
    }

    &__text {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;

      @include respond-to(lg) {
        margin: 0 0 30px 0;
      }

      @include respond-to(sm) {
        display: block;
      }
    }

    &__text-left {
      width: 40%;

      @include respond-to(lg) {
        width: 50%;
      }

      @include respond-to(sm) {
        width: 100%;
        margin: 0 0 0 0;
      }

      &--right-empty {
        margin: 0 0 -20px 0;
        width: 100%;

        @include respond-to(sm) {
          margin: 0 0 0 0;
        }
      }
    }

    &__last-name-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__last-name-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__last-name-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__first-name-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__first-name-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__first-name-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__extension-number-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__extension-number-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__extension-number-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__middle-name-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__middle-name-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__middle-name-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__nationality-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__nationality-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__nationality-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__email-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__email-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__email-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__phone-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__phone-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__phone-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__text-right {
      width: 40%;

      @include respond-to(lg) {
        width: 50%;
      }

      @include respond-to(sm) {
        width: 100%;
        margin: 0 0 0 0;
      }

      &--right-empty {
        width: 0;
      }
    }

    &__profile-picture {
      display: flex;
      flex-direction: column;
      gap: 6px;

      @include respond-to(lg) {
        width: 50%;
        flex-direction: row;
        gap: 0;
      }

      @include respond-to(sm) {
        width: 100%;
      }
    }

    &__tax-residence-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__tax-residence-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__tax-residence-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__birthday-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__birthday-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__birthday-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.DateCell__time) {
        display: none !important;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__country-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__country-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__country-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__region-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__region-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__region-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__city-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__city-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__city-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__address-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__address-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
      margin: 0 5px 0 0;
    }

    &__address-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__first-deposit-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__first-deposit-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
    }

    &__first-deposit-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      > div {
        display: flex;
        gap: 5px;
      }

      span {
        font-size: 15px;
        line-height: 16px;
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__is-partner-wrap {
      display: flex;
      margin: 0 0 20px 0;
    }

    &__is-partner-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      width: 40%;
    }

    &__profile-photo-title {
      font-size: 13px;
      line-height: 18px;
      color: #777;
      @include respond-to(lg) {
        width: 40%;
      }
    }

    &__is-partner-data {
      width: 60%;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-start;

      span {
        font-size: 15px;
        line-height: 16px;

        &:after {
          top: 7px !important;
        }
      }

      :global(.ActionsButton) {
        display: flex;
      }

      :global(.ActionsButton > svg) {
        width: 16px;
        height: 16px;
      }
    }

    &__photo {
      width: 142px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include respond-to(lg) {
        width: 170px;
      }

      :global(.react-gravatar) {
        width: 100%;
        height: auto;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserInfoPanel__inform-block {
      background-color: ${colors.white.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
