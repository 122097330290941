import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import { NoItemsPanel, PageContentPreloader, SearchPanel, TransfersTable } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getInternalTransferList } from 'client/transfers/_redux';

const TransferListPage = ({
  internalTransferList,
  internalTransferListIsLoaded,
  getInternalTransferList,
  history,
  location,
  intl,
}) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };
  /* eslint-disable */
  useEffect(() => {
    getInternalTransferList({ search: query });
  }, [getInternalTransferList]);
  /* eslint-enable */

  const handleRowClick = id => history.push(`/payments/transfers/${id}/`);

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'IN_PROGRESS',
          label: intl.formatMessage({ id: 'status_in_progress' }),
        },
        {
          value: 'WAITING_FOR_APPROVAL',
          label: intl.formatMessage({ id: 'status_waiting_for_approval' }),
        },
        {
          value: 'COMPLETED',
          label: intl.formatMessage({ id: 'status_completed' }),
        },
        { value: 'FAILED', label: intl.formatMessage({ id: 'status_failed' }) },
      ],
    },
    {
      title: 'boTransferType',
      accessor: 'transferType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        {
          value: 'INNER',
          label: intl.formatMessage({ id: 'boInnerTransfer' }),
        },
        {
          value: 'TO_TRADER',
          label: intl.formatMessage({ id: 'boAnotherTrader' }),
        },
      ],
    },
  ];

  return (
    <PageContentPreloader ready={internalTransferListIsLoaded} type="bigLine">
      {!internalTransferList.count && !location.search ? (
        ''
      ) : (
        <SearchPanel
          location={location}
          history={history}
          getList={getInternalTransferList}
          filters={filters}
          params={{ search: query }}
        />
      )}
      {internalTransferList.count ? (
        <TransfersTable
          data={internalTransferList}
          loading={!internalTransferListIsLoaded}
          location={location}
          history={history}
          getList={getInternalTransferList}
          onRowClick={handleRowClick}
          params={{ search: query }}
          forClient
        />
      ) : (
        <NoItemsPanel
          icon={noPaymentsImage}
          captionSlug={location.search ? 'noTransfersMatchingTheParameters' : 'noInternalTransfers'}
        />
      )}
    </PageContentPreloader>
  );
};

TransferListPage.propTypes = {
  internalTransferList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  internalTransferListIsLoaded: PropTypes.bool.isRequired,
  getInternalTransferList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      internalTransferList: state.client.transfers.internalTransferList,
      internalTransferListIsLoaded: state.client.transfers.internalTransferListIsLoaded,
    }),
    {
      getInternalTransferList: data => getInternalTransferList.request(data),
    }
  )
)(TransferListPage);
