import React from 'react';
import classNames from 'classnames';
import { withTheme } from 'common/styling/theme';

import Tooltip from '../Tooltip';

import { staticStyles, getDynamicStyles } from './style';

export interface IActionsButtonProps {
  condition?: boolean;
  className?: string;
  onClickFunc?: any;
  onMouseEnterFunc?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  edit?: boolean;
  deleteType?: boolean;
  watch?: boolean;
  tooltipId?: string;
  tooltipTextId?: string;
  buttonId?: string;
  theme?: any;
}

const ActionsButton: React.FunctionComponent<IActionsButtonProps> = ({
  condition,
  edit,
  deleteType,
  watch,
  className,
  onClickFunc,
  onMouseEnterFunc,
  disabled,
  children,
  tooltipId,
  tooltipTextId,
  buttonId,
  theme,
}: IActionsButtonProps) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  return (
    <>
      {condition && (
        <button
          className={classNames('ActionsButton', className, {
            ActionsButton__edit: edit,
            ActionsButton__delete: deleteType,
            ActionsButton__watch: watch,
          })}
          id={buttonId}
          type="button"
          tabIndex={-1}
          disabled={disabled}
          onClick={onClickFunc}
          onMouseEnter={onMouseEnterFunc}>
          <Tooltip id={tooltipId} textId={tooltipTextId}>
            {children}
          </Tooltip>
        </button>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

ActionsButton.defaultProps = {
  children: null,
  condition: true,
  className: '',
  onClickFunc: null,
  onMouseEnterFunc: null,
  disabled: false,
  edit: false,
  deleteType: false,
  watch: false,
  tooltipId: '',
  tooltipTextId: '',
  buttonId: '',
  theme: {},
};

export default withTheme()(ActionsButton);
export { ActionsButton };
