import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { LightBoxModal } from 'common/components';
import { closeLightBox } from 'interface/user/_redux/actions';
import { withTheme } from 'common/styling/theme';

import { UserNoInfoPanel } from 'backoffice/_components/';
import noInfoImage from 'common/images/noInfo.svg';
import classNames from 'classnames';
import UserDocuments from '../UserDocuments';

import { staticStyles, getDynamicStyles } from './style';
import check from './check.svg';
import arrowTopKYC from './arrowTopKYC.svg';

const UserDocumentsForm = ({
  documents,
  user,
  documentsUploadErrors,
  error,
  isPassportEnabled,
  isIdentificationProofEnabled,
  isAddressProofEnabled,
  isPassportWithFaceEnabled,
  isAdditionalDocumentEnabled,
  passportDocuments,
  addressProofDocuments,
  identificationProofDocuments,
  passportWithFaceDocuments,
  additionalDocuments,
  isDocumentsAreUploaded,
  lightBoxCurrentImage,
  lightBoxImages,
  closeLightBox,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [openBlock, setOpenBlock] = useState(false);
  const [filter, setFilter] = useState(false);
  passportDocuments = passportDocuments.filter(({ status }) => (filter ? status === 'unverified' : true));
  addressProofDocuments = addressProofDocuments.filter(({ status }) => (filter ? status === 'unverified' : true));
  identificationProofDocuments = identificationProofDocuments.filter(({ status }) =>
    filter ? status === 'unverified' : true
  );
  passportWithFaceDocuments = passportWithFaceDocuments.filter(({ status }) =>
    filter ? status === 'unverified' : true
  );
  additionalDocuments = additionalDocuments.filter(({ status }) => (filter ? status === 'unverified' : true));

  return (
    <form className="UserDocumentsForm">
      {/* eslint-disable */}
      <div
        className={classNames('UserDocumentsForm__open-close', {
          'UserDocumentsForm__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <FormattedMessage id="justDocuments">
        {txt => (
          <h2
            className={classNames('UserDocumentsForm__title', {
              'UserDocumentsForm__title--hide': !openBlock,
            })}>
            {txt}
          </h2>
        )}
      </FormattedMessage>
      {/* eslint-disable */}
      <img
        className={classNames('UserDocumentsForm__arrowTopKYC', {
          'UserDocumentsForm__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      {isDocumentsAreUploaded ? (
        <div
          className={classNames('UserDocumentsForm__block-text', {
            'UserDocumentsForm__block-text--hide': !openBlock,
          })}>
          {/* eslint-disable */}
          <div className="UserDocumentsForm__block-subtext" onClick={() => setFilter(!filter)}>
            <div
              className={classNames('UserDocumentsForm__block-subtext-check', {
                'UserDocumentsForm__block-subtext-check--filter': filter,
              })}>
              <img src={check} alt="check" />
            </div>
            <FormattedMessage id="showOnlyDocsForVerificationKYC">
              {txt => <div className="UserDocumentsForm__block-subtext-title">{txt}</div>}
            </FormattedMessage>
          </div>
          {/* eslint-enable */}

          {isPassportEnabled && !!documents.passport.length && !!passportDocuments.length && (
            <UserDocuments
              filter={filter}
              doc="passport"
              user={user}
              titleId="boCopyOfPassportWithResidAddress"
              name="passport"
              fileNameId="justPassport"
              uploadError={documentsUploadErrors.passport}
              documents={passportDocuments}
            />
          )}

          {isAddressProofEnabled && !!documents.address_proof.length && !!addressProofDocuments.length && (
            <UserDocuments
              filter={filter}
              doc="address_proof"
              user={user}
              titleId="proofResidAddress"
              name="address_proof"
              fileNameId="addressProof"
              uploadError={documentsUploadErrors.address_proof}
              documents={addressProofDocuments}
            />
          )}

          {isIdentificationProofEnabled &&
            !!documents.identification_proof.length &&
            !!identificationProofDocuments.length && (
              <UserDocuments
                filter={filter}
                doc="identification_proof"
                user={user}
                titleId="proofOfIdent"
                name="identification_proof"
                fileNameId="identificationPoof"
                uploadError={documentsUploadErrors.identification_proof}
                documents={identificationProofDocuments}
              />
            )}
          {isPassportWithFaceEnabled && !!documents.passport_with_face.length && !!passportWithFaceDocuments.length && (
            <UserDocuments
              filter={filter}
              doc="passport_with_face"
              user={user}
              titleId="proofOfLife"
              name="passport_with_face"
              fileNameId="passportWithFacePhotoProof"
              uploadError={documentsUploadErrors.passport_with_face}
              documents={passportWithFaceDocuments}
            />
          )}
          {isAdditionalDocumentEnabled && !!documents.additional_document.length && !!additionalDocuments.length && (
            <UserDocuments
              filter={filter}
              doc="additional_document"
              user={user}
              titleId="additionalDocumentCaption"
              name="additional_document"
              fileNameId="additionalDocument"
              uploadError={documentsUploadErrors.additional_document}
              documents={additionalDocuments}
            />
          )}
        </div>
      ) : (
        <div
          className={classNames('UserDocumentsForm__noInfoPanel', {
            'UserDocumentsForm__noInfoPanel--hide': !openBlock,
          })}>
          <UserNoInfoPanel image={noInfoImage} textId="boUserDocumentsNoInform" />
        </div>
      )}

      {error && <strong className="formError">{error}</strong>}

      <LightBoxModal images={[lightBoxImages]} currentImage={lightBoxCurrentImage} onRequestClose={closeLightBox} />

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </form>
  );
};

UserDocumentsForm.propTypes = {
  documents: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  documentsUploadErrors: PropTypes.object.isRequired,
  isPassportEnabled: PropTypes.bool,
  isIdentificationProofEnabled: PropTypes.bool,
  isAddressProofEnabled: PropTypes.bool,
  isPassportWithFaceEnabled: PropTypes.bool,
  isAdditionalDocumentEnabled: PropTypes.bool,
  isDocumentsAreUploaded: PropTypes.bool,
  passportDocuments: PropTypes.array,
  addressProofDocuments: PropTypes.array,
  identificationProofDocuments: PropTypes.array,
  passportWithFaceDocuments: PropTypes.array,
  additionalDocuments: PropTypes.array,
  lightBoxCurrentImage: PropTypes.number,
  lightBoxImages: PropTypes.array,
  closeLightBox: PropTypes.func,
  theme: PropTypes.object,
  error: PropTypes.string,
};

UserDocumentsForm.defaultProps = {
  error: '',
  closeLightBox: null,
  lightBoxCurrentImage: -1,
  lightBoxImages: [],
  isPassportEnabled: false,
  isIdentificationProofEnabled: false,
  isAddressProofEnabled: false,
  isPassportWithFaceEnabled: false,
  isAdditionalDocumentEnabled: false,
  isDocumentsAreUploaded: false,
  passportDocuments: [],
  addressProofDocuments: [],
  identificationProofDocuments: [],
  passportWithFaceDocuments: [],
  additionalDocuments: [],
  theme: {},
};

export default compose(
  connect(
    (state, second) => {
      const { documents } = second;
      const isPassportEnabled = state.interfaceConfig.kycSettings.passport.enable;
      const isIdentificationProofEnabled = state.interfaceConfig.kycSettings.identification_proof.enable;
      const isAddressProofEnabled = state.interfaceConfig.kycSettings.address_proof.enable;
      const isPassportWithFaceEnabled = state.interfaceConfig.kycSettings.passport_with_face?.enable;
      const isAdditionalDocumentEnabled = state.interfaceConfig.kycSettings.additional_document.enable;
      const lightBoxCurrentImage = state.user.lightBoxCurrentImage;
      const lightBoxImages = state.user.lightBoxImages;

      const passportDocuments = documents.passport.filter(document => document?.file);
      const addressProofDocuments = documents.address_proof.filter(document => document?.file);
      const passportWithFaceDocuments = documents.passport_with_face.filter(document => document?.file);
      const identificationProofDocuments = documents.identification_proof.filter(document => document?.file);
      const additionalDocuments = documents.additional_document.filter(document => document?.file);

      const isDocumentsAreUploaded =
        (isPassportEnabled && !!documents.passport.length) ||
        (isAddressProofEnabled && !!documents.address_proof.length) ||
        (isPassportWithFaceEnabled && !!documents.passport_with_face.length) ||
        (isIdentificationProofEnabled && !!documents.identification_proof.length) ||
        (isAdditionalDocumentEnabled && !!documents.additional_document.length);

      return {
        isPassportEnabled,
        isIdentificationProofEnabled,
        isAddressProofEnabled,
        isPassportWithFaceEnabled,
        isAdditionalDocumentEnabled,
        lightBoxCurrentImage,
        lightBoxImages,
        passportDocuments,
        addressProofDocuments,
        passportWithFaceDocuments,
        identificationProofDocuments,
        additionalDocuments,
        isDocumentsAreUploaded,
      };
    },
    {
      closeLightBox,
    }
  ),
  reduxForm({ form: 'boUserDocumentsForm' }),
  withTheme()
)(UserDocumentsForm);
export { UserDocumentsForm };
