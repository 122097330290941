import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  bonusesInfoList: {},
  bonusesInfoListIsLoaded: false,

  bonusInfo: {},
  bonusInfoIsLoaded: false,

  bonusTypes: [],
  bonusTypesAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BONUSES_INFO_LIST[REQUEST]:
      return { ...state, bonusesInfoListIsLoaded: false };
    case ActionTypes.GET_BONUSES_INFO_LIST[SUCCESS]:
      return {
        ...state,
        bonusesInfoList: action.data,
        bonusesInfoListIsLoaded: true,
      };
    case ActionTypes.GET_BONUSES_INFO_LIST[FAILURE]:
      return { ...state, error: action.error, bonusesInfoListIsLoaded: false };

    case ActionTypes.GET_BONUS_INFO[REQUEST]:
      return { ...state, bonusInfoIsLoaded: false };
    case ActionTypes.GET_BONUS_INFO[SUCCESS]:
      return { ...state, bonusInfo: action.data, bonusInfoIsLoaded: true };
    case ActionTypes.GET_BONUS_INFO[FAILURE]:
      return { ...state, error: action.error, bonusInfoIsLoaded: false };

    case ActionTypes.GET_BONUS_TYPES[REQUEST]:
      return { ...state, bonusTypesAreLoaded: false };
    case ActionTypes.GET_BONUS_TYPES[SUCCESS]:
      return { ...state, bonusTypes: action.data, bonusTypesAreLoaded: true };
    case ActionTypes.GET_BONUS_TYPES[FAILURE]:
      return { ...state, error: action.error, bonusTypesAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
