import React from 'react';
import { connect } from 'react-redux';

import { getTradingAccount, getTradingAccountList, getTradingAccountTypes } from '../../../_redux';

import { getDepositSystemList, getAvailableLpas } from '../../_redux';
import { PaymentsPanel } from '../../_components';

const DepositPage = props => <PaymentsPanel {...props} />;

export default connect(
  state => {
    const action = state.interfaceConfig.addons.includes('walletAccount') ? 'wallet_deposit' : 'deposit';

    return {
      paymentSystemList: state.client.payments.depositSystems,
      availableLpasList: state.client.payments.availableLpas,
      initialLoading: state.client.common.loadingAccounts || state.client.payments.loadingDepositSystems,
      addons: state.interfaceConfig.addons,
      captionSlug: 'clientDeposit',
      action,
    };
  },
  {
    loadAccounts: () => getTradingAccountList.request(true),
    loadPaymentSystems: () => getDepositSystemList.request(),
    getAvailableLpas: () => getAvailableLpas.request(),
    loadAccount: id => getTradingAccount.request(id),
    getTradingAccountTypes: () => getTradingAccountTypes.request(),
  }
)(DepositPage);
export { DepositPage };
