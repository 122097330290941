import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserDocumentsForm {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    margin-bottom: rem(20px);
    @include respond-to(sm) {
      padding: rem(20px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserDocumentsForm__block-text {
    display: flex;
    flex-direction: column;
  }

  .UserDocument__buttons-inner {
    span {
      width: 100%;
      padding: 0;
      color: inherit !important;
    }
  }

  .UserOneDocumentForm {
    .UserDocumentsForm__block-text {
      justify-content: center;
    }
    .UserDocument__inner {
      width: 50%;
      padding: 0;

      @include respond-to(lg) {
        width: 100%;
      }
    }
  }

  .UserTwoDocumentsForm {
    .UserDocument__inner {
      width: 49%;
      @include respond-to(lg) {
        width: 100%;
      }
    }
  }

  .UserFourDocumentsForm {
    .UserDocument__inner {
      width: 49%;
      &:nth-child(2) {
        border: none;
      }
      &:nth-child(3),
      &:nth-child(4) {
        padding-top: 20px;
        @include respond-to(lg) {
          padding-top: 0;
        }
      }
      @include respond-to(lg) {
        width: 100%;
      }
    }
  }

  .UserDocumentsForm {
    position: relative;
    margin: 0 0 20px 0;
    padding: 0 0 0 0;

    @include respond-to(sm) {
      padding: 0 0 0 0;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__title {
      padding: 25px 0 25px 25px;
      margin: 0 0 0 0 !important;

      @include respond-to(sm) {
        padding: 18px 0 18px 13px;
      }

      &--hide {}
    }
    
    &__block-text {
      &--hide {
        display: none;
      }
    }

    &__block-subtext {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 0 19px 25px;
      border-bottom: 2px solid #e9e9e9;

      @include respond-to(sm) {
        padding: 0 0 19px 13px;
      }
    }

    &__block-subtext-check {
      width: 20px;
      height: 20px;
      margin: 0 14px 0 0;
      border-radius: 3px;
      border: 1px solid #C5C5C5;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        opacity: 0;
      }

      &--filter {
        border-color: #00B6E3;
        background: #00B6E3;

        img {
          opacity: 1;
        }
      }
    }

    &__block-subtext-title {
      font-size: 15px;
      line-height: 20px;
    }

    &__noInfoPanel {
      &--hide {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserDocumentsForm {
      background-color: ${colors.white.hex};
    }

    .UserDocumentsForm__block-text {
      span {
        color: ${colors.gray.hex};
        &:nth-child(2) {
          color: ${colors.black.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
