import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { PageLayout, PageTabs, TranslatedNavLink } from 'common/components';

import WarningMessage from '../../../_components/WarningMessage';

import staticStyles from './style';

const VerificationPanel = ({ captionId, warningMessageTextId, status, kycEnabled, documents, children }) => {
  const documentUploadedStatuses = ['unverified', 'verified'];
  const documentsAreUploaded = Object.values(documents).every(doc => documentUploadedStatuses.includes(doc));
  const documentsAreDisabled = Object.values(documents).every(doc => doc === 'disabled');
  const isVerifiedStatus = ['verified', 'verified_new_documents'].includes(status);

  const [ref, inView] = useInView();
  const [ref2, inView2] = useInView();
  const showInView = inView ? 'inView' : '';
  const showInView2 = inView2 ? 'inView2' : '';
  const classInView = inView && inView2 ? '' : (inView ? showInView : '') || (inView2 ? showInView2 : '');

  return (
    <PageLayout captionSlug={captionId}>
      <div className={`VerificationPanel ${classInView}`}>
        {status === 'incomplete' && <WarningMessage bodyTextId={warningMessageTextId} />}

        <PageTabs forVerification>
          <ol>
            {!documentsAreDisabled && (
              <li
                className={classNames('forVerification__tab-counter-inner', {
                  done: documentsAreUploaded || isVerifiedStatus,
                })}>
                <TranslatedNavLink id="menuDocuments" to="/documents" />
              </li>
            )}
            <li
              className={classNames('forVerification__tab-counter-inner', {
                done: status !== 'incomplete',
              })}
              ref={ref}>
              <TranslatedNavLink id="menuProfile" to="/personal_profile" />
            </li>
            {(!kycEnabled || isVerifiedStatus) &&
              document.location.hostname !== 'my.swaymarkets.com' &&
              document.location.hostname !== 'my.finessefx.io' &&
              document.location.hostname !== 'portal.disona.com' &&
              document.location.hostname !== 'portal.rock-west.com' && (
                <li
                  className={classNames('forVerification__tab-counter-inner', {
                    done: status !== 'incomplete',
                  })}>
                  <TranslatedNavLink id="justMyCards" to="/cards" />
                </li>
              )}

            {isVerifiedStatus && (
              <>
                <li
                  className={classNames('forVerification__tab-counter-inner', {
                    done: isVerifiedStatus,
                  })}>
                  <TranslatedNavLink id="justSecurity" to="/change_password" />
                </li>
                <li
                  className={classNames('forVerification__tab-counter-inner', {
                    done: isVerifiedStatus,
                  })}>
                  <TranslatedNavLink id="justNotificationSettings" to="/notification_settings" />
                </li>
                <li
                  className={classNames('forVerification__tab-counter-inner', {
                    done: isVerifiedStatus,
                  })}
                  ref={ref2}>
                  <TranslatedNavLink id="justAgreements" to="/agreements" />
                </li>
              </>
            )}
          </ol>
        </PageTabs>

        {children}

        <style jsx global>
          {staticStyles}
        </style>
      </div>
    </PageLayout>
  );
};

VerificationPanel.propTypes = {
  captionId: PropTypes.string.isRequired,
  warningMessageTextId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  documents: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  kycEnabled: PropTypes.bool.isRequired,
};

export default connect(state => {
  const { status, passportStatus, identificationProofStatus, addressProofStatus } = state.user.profile;
  const kycEnabled = state.interfaceConfig.kycSettings.enabled;

  return {
    status,
    kycEnabled,
    documents: {
      passportStatus,
      identificationProofStatus,
      addressProofStatus,
    },
  };
})(VerificationPanel);
export { VerificationPanel };
