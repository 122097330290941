import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const GET_KYC = createRequestTypes('GET_KYC');
export const getKYC = {
  request: () => action(GET_KYC[REQUEST]),
  success: data => action(GET_KYC[SUCCESS], { data }),
  failure: error => action(GET_KYC[FAILURE], { error }),
};

export const sendKYC = createFormAction('SEND_KYC');

export const SEND_DOCUMENTS = createRequestTypes('SEND_DOCUMENTS');
export const sendDocuments = {
  request: data => action(SEND_DOCUMENTS[REQUEST], { ...data }),
  success: name => action(SEND_DOCUMENTS[SUCCESS], { name }),
  failure: error => action(SEND_DOCUMENTS[FAILURE], { error }),
};

export const TOGGLE_REUPLOAD_DOCUMENTS = 'TOGGLE_REUPLOAD_DOCUMENTS';
export const toggleReuploadDocuments = name => action(TOGGLE_REUPLOAD_DOCUMENTS, { name });

export const GET_COUNTRIES = createRequestTypes('GET_COUNTRIES');
export const getCountries = {
  request: () => action(GET_COUNTRIES[REQUEST]),
  success: data => action(GET_COUNTRIES[SUCCESS], { data }),
  failure: error => action(GET_COUNTRIES[FAILURE], { error }),
};

export const GET_STATES = createRequestTypes('GET_STATES');
export const getStates = {
  request: gid => action(GET_STATES[REQUEST], { gid }),
  success: data => action(GET_STATES[SUCCESS], { data }),
  failure: error => action(GET_STATES[FAILURE], { error }),
};

export const changePassword = createFormAction('CHANGE_PASSWORD');

export const GET_NOTIFICATION_SETTINGS = createRequestTypes('GET_NOTIFICATION_SETTINGS');
export const getNotificationSettings = {
  request: () => action(GET_NOTIFICATION_SETTINGS[REQUEST]),
  success: data => action(GET_NOTIFICATION_SETTINGS[SUCCESS], { data }),
  failure: error => action(GET_NOTIFICATION_SETTINGS[FAILURE], { error }),
};

export const changeNotificationSettings = createFormAction('CHANGE_NOTIFICATION_SETTINGS');

export const GET_AGREEMENTS = createRequestTypes('GET_AGREEMENTS');
export const getAgreements = {
  request: () => action(GET_AGREEMENTS[REQUEST]),
  success: data => action(GET_AGREEMENTS[SUCCESS], { data }),
  failure: error => action(GET_AGREEMENTS[FAILURE], { error }),
};

export const GET_CARDS = createRequestTypes('GET_CARDS');
export const getCards = {
  request: () => action(GET_CARDS[REQUEST]),
  success: data => action(GET_CARDS[SUCCESS], { data }),
  failure: error => action(GET_CARDS[FAILURE], { error }),
};

export const GET_CARD = createRequestTypes('GET_CARD');
export const getCard = {
  request: id => action(GET_CARD[REQUEST], { id }),
  success: data => action(GET_CARD[SUCCESS], { data }),
  failure: error => action(GET_CARD[FAILURE], { error }),
};

export const DELETE_CARD = createRequestTypes('DELETE_CARD');
export const deleteCard = {
  request: id => action(DELETE_CARD[REQUEST], { id }),
  success: () => action(DELETE_CARD[SUCCESS]),
  failure: error => action(DELETE_CARD[FAILURE], { error }),
};

export const addCard = createFormAction('ADD_CARD');

export const updateCard = createFormAction('UPDATE_CARD');
