import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValues, change } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxSelectField } from 'common/components';
import FormFieldsComposer from '../FormFieldsComposer';

import { staticStyles } from './style';

class DynamicField extends React.Component {
  componentDidUpdate(prevProps) {
    const { selectedValue, name, onSelectedValueChange, formName } = this.props;

    if (prevProps.selectedValue && prevProps.selectedValue !== selectedValue) {
      onSelectedValueChange(formName, `${name}.value`, null);
    }
  }

  render() {
    const { name, schema, selectedValue, formName, submitting } = this.props;
    const options = Object.keys(schema.options).map(value => ({
      value,
      label: schema.options[value].title,
    }));

    return (
      <div className="DynamicField">
        <div className="DynamicField__select-inner">
          <Field
            validate={required()}
            name={`${name}.type`}
            component={ReduxSelectField}
            textId={schema.title || 'justType'}
            options={options}
            disabled={submitting}
            customDefaultValue={schema.default_option}
            searchable
          />
        </div>
        {selectedValue && (
          <FormFieldsComposer
            name={`${name}.value`}
            title={schema.options[selectedValue].title}
            schema={schema.options[selectedValue].form}
            formName={formName}
            submitting={submitting}
          />
        )}
        <style jsx>{staticStyles}</style>
      </div>
    );
  }
}

DynamicField.propTypes = {
  name: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  selectedValue: PropTypes.string,
  onSelectedValueChange: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
};

DynamicField.defaultProps = {
  submitting: false,
  selectedValue: '',
};

export default compose(
  connect(undefined, { onSelectedValueChange: change }),
  formValues(props => ({ selectedValue: `${props.name}.type` }))
)(DynamicField);
export { DynamicField };
