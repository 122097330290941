import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserOperations {
    .UserOperations__tabs {
      margin-bottom: rem(30px);
      @include respond-to(sm) {
        width: 100%;
      }
    }

    .UserOperations__tab {
      font-size: 18px;
      line-height: 23px;
      margin-right: ${isRTL ? '0' : '35px'};
      margin-left: ${isRTL ? '35px' : '0'};
      padding-bottom: 15px;
      cursor: pointer;
      border-bottom: 3px solid transparent;

      &:last-child {
        margin-right: 0;
      }

      @include respond-to(sm) {
        font-size: 15px;
        line-height: normal;
        padding-bottom: 5px;
        border-bottom-width: 2px;
        margin-right: ${isRTL ? '0' : '15px'};
        margin-left: ${isRTL ? '15px' : '0'};
      }
    }

    .UserOperations__wallet-balance {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .UserOperations__wallet-balance-caption {
      font-size: rem(14px);
      color: #777777;
      margin-right: 5px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .UserOperations {
      .UserOperations__tab {
        &.active {
          color: ${colors.primary.hex};
          border-bottom-color: ${colors.primary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
