import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import formActionSaga from 'redux-form-saga';
import { createLogger } from 'redux-logger';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';

import reducers from './rootReducer';
import rootSaga from './rootSaga';

export default function () {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  let composeEnhancers = compose;

  // connect browser DevTools and Logger on dev mode
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true,
      duration: true,
    });
    middlewares.push(logger);

    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension;
    }
  }

  const store = createStore(reducers, composeEnhancers(applyMiddleware(createRavenMiddleware(Raven), ...middlewares)));

  const sagas = [rootSaga, formActionSaga];
  sagas.forEach(item => {
    sagaMiddleware.run(item);
  });
  return store;
}
