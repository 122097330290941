import moment from 'moment';

const currentDate = moment();
const currentMonth = moment().format('YYYY-MM');
const lastMonth = moment().subtract(1, 'months').format('YYYY-MM');
const currentMonthName = moment().format('MMMM');
const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
const endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
const today = moment().format('YYYY-MM-DD');
const displayToday = moment().format('DD.MM.YYYY');
const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

const getFixedNumber = (num, decimal) => {
  const result = Number(Number(num).toFixed(decimal));
  if (!Number.isNaN(result)) {
    return result;
  }
  return '';
};

export {
  currentDate,
  currentMonth,
  lastMonth,
  currentMonthName,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  today,
  displayToday,
  tomorrow,
  getFixedNumber,
};
