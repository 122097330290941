import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { PaginationTable, StatusCell, DateCell, TableLoader, ActionsButton } from 'common/components';
import UserActivity from 'backoffice/_components/UserActivity';
import { withTheme } from 'common/styling/theme';
import { PhoneIcon } from 'backoffice/managers/_components/TaskIcon/icons';
import ProvidersPopup from '../ProvidersPopup';
import { staticStyles, getDynamicStyles } from './style';

const BOUsersTable = ({
  data,
  onRowClick,
  loading,
  location,
  history,
  params,
  getList,
  hasPaymentOperationManagers,
  kycSettings,
  theme,
  addons,
  profilePerms,
  withManagers,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [modalId, setModalId] = useState(0);
  const [buttonPosition, setButtonPosition] = useState([0, 0]);
  const withClick2Call = !!profilePerms.managerViewUser && !!profilePerms.viewUser && addons.includes('click2call');
  const withManagersAndCall = withManagers && withClick2Call;

  const paginationTableRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const getPopupPosition = (buttonPosition, tablePosition) => {
    const topDiff = isMobile ? 30 : 0;
    const rightDiff = isMobile ? 80 : 210;
    const top = buttonPosition.y - tablePosition.y + topDiff;
    const right = buttonPosition.x - tablePosition.x - rightDiff;
    setButtonPosition([top, right]);
  };

  const toggleModalOpen = (id, elem, isHover) => {
    if (isHover) {
      setModalOpen(true);
    } else {
      setModalOpen(!isModalOpen);
    }

    if (id) {
      setModalId(id);
      getPopupPosition(elem.getBoundingClientRect(), paginationTableRef.current.getBoundingClientRect());
    }
  };

  /* eslint-disable */
  const columns = [
    {
      Header: () => null,
      id: 'avatar',
      Cell: ({
        row: {
          original: { email },
        },
      }) => <Gravatar md5={md5(email)} size={35} />,
      isPreviewCell: true,
    },
    {
      Header: 'justFullName',
      Cell: ({
        row: {
          original: { fullName },
        },
      }) => <span>{fullName}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justEmail',
      Cell: ({
        row: {
          original: { email },
        },
      }) => <span>{email}</span>,
      isPreviewDescriptionCell: true,
    },
    {
      Header: 'justPhone',
      Cell: ({
        row: {
          original: { phone },
        },
      }) => phone,
      isPreviewCell: true,
    },
    {
      Header: 'justDateJoined',
      Cell: ({
        row: {
          original: { dateJoined },
        },
      }) => <DateCell value={dateJoined} />,
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            Header: 'justManager',
            Cell: ({
              row: {
                original: { manager },
              },
            }) => <span>{manager ? `${manager.firstName} ${manager.lastName}` : '一'}</span>,
          },
        ]
      : []),
    {
      Header: 'justPartner',
      Cell: ({
        row: {
          original: { partnerAccount },
        },
      }) => <span>{partnerAccount ?? '一'}</span>,
    },
    {
      Header: 'justIbAccounts',
      Cell: ({
        row: {
          original: { ibAccounts },
        },
      }) => (
        <span className="BOUsersTable__IbAccounts">
          {ibAccounts && ibAccounts.length
            ? ibAccounts.map((ibAccount, ind) => (
                <span key={`BOUsersTable__IbAccount-${ind}`} className="BOUsersTable__IbAccount">
                  {ibAccount}
                </span>
              ))
            : '一'}
        </span>
      ),
    },
    ...(kycSettings.enabled
      ? [
          {
            Header: 'boStatus',
            Cell: ({
              row: {
                original: { status },
              },
            }) => <StatusCell statusCode={status} noDot />,
          },
        ]
      : []),
    {
      Header: 'kycIsActive',
      Cell: ({
        row: {
          original: { isActive, isOnline, lastSeen },
        },
      }) => <UserActivity isBlocked={!isActive} isOnline={isOnline} lastSeen={lastSeen} onlyDot />,
    },
  ];
  if (withClick2Call) {
    columns.push({
      Header: 'justCall',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <ActionsButton
          onClickFunc={evt => {
            evt.stopPropagation();
            toggleModalOpen(id, evt.target);
          }}
          onMouseEnterFunc={evt => {
            evt.stopPropagation();
            toggleModalOpen(id, evt.target, true);
          }}>
          <PhoneIcon />
        </ActionsButton>
      ),
    });
  }
  /* eslint-enable */

  return (
    <div
      className={classNames(
        'BOUsersTable',
        {
          'BOUsersTable--withManagers': withManagers,
        },
        {
          'BOUsersTable--withManagersAndCall': withManagersAndCall,
        }
      )}
      onMouseLeave={evt => {
        evt.stopPropagation();
        setModalOpen(false);
      }}
      ref={paginationTableRef}>
      {loading && <TableLoader />}
      {!loading && (
        <PaginationTable
          data={data}
          columns={columns}
          loading={loading}
          getList={getList}
          location={location}
          history={history}
          params={params}
          onRowClick={onRowClick}
          isNewTable
        />
      )}
      {isModalOpen ? (
        <ProvidersPopup id={modalId} buttonPosition={buttonPosition} toggleModalOpen={toggleModalOpen} />
      ) : null}

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BOUsersTable.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
        email: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        dateJoined: PropTypes.string.isRequired,
      })
    ),
  }),
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  kycSettings: PropTypes.object.isRequired,
  hasPaymentOperationManagers: PropTypes.bool,
  theme: PropTypes.object,
  addons: PropTypes.array,
  profilePerms: PropTypes.object,
};

BOUsersTable.defaultProps = {
  data: {},
  loading: false,
  hasPaymentOperationManagers: false,
  theme: {},
  addons: [],
  profilePerms: {},
};

export default withTheme()(BOUsersTable);
export { BOUsersTable };
