/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { PageContentPreloader } from 'common/components';
import NewCRMTable from '../NewCRMTableNoQuery';
import NewCRMPagination from '../NewCRMPaginationNoQuery';

import { staticStyles } from './style';

const NoQueryTable = ({ data, dataIsLoaded, columns, onRowClick, getData }) => {
  const [ordering, setOrdering] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getData({ search: { ordering, page_size: pageSize, page: currentPage } });
  }, [ordering, pageSize, currentPage]);

  return (
    <div className="NoQueryTable">
      <PageContentPreloader ready={dataIsLoaded} firstLaunchOnly type="accounts">
        {/* temporaly full data due backend not sending count, and otehrs */}
        <NewCRMTable
          ordering={ordering}
          onRowClick={onRowClick}
          setOrdering={setOrdering}
          data={data.results}
          columns={columns}
        />
        {!!data.count && (
          <NewCRMPagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            count={data.count}
          />
        )}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

NoQueryTable.propTypes = {
  data: PropTypes.object.isRequired,
  dataIsLoaded: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  getData: PropTypes.func.isRequired,
};

NoQueryTable.defaultProps = {
  onRowClick: null,
};

export default compose(withRouter)(NoQueryTable);
