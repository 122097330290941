import css from 'styled-jsx/css';
import { gradient, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TopMenu__switch-interface-inner {
    display: inline-block;
    position: relative;
    z-index: 15;
    @include respond-to(md) {
      display: none;
      max-width: 160px;
      margin-left: ${isRTL ? '0' : '10px'};
      margin-right: ${isRTL ? '10px' : '0'};
    }

    .Select__value-container {
      overflow: visible;
    }

    .Select__single-value {
      text-transform: uppercase !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      cursor: pointer !important;
      letter-spacing: 1px !important;
      white-space: normal;
      @include respond-to(md) {
        font-size: 12px !important;
      }
      @include respond-to(md) {
        letter-spacing: 0.3px !important;
        font-size: 11px !important;
      }
    }

    .Select__menu {
      top: 48px !important;
      min-width: 180px;
      @include respond-to(md) {
        top: 43px !important;
      }
    }

    .Select__control {
      justify-content: flex-start;
      border: none !important;
      padding: 0 !important;
    }

    .Select__option {
      text-transform: uppercase !important;
      font-weight: 500 !important;
      letter-spacing: 1px !important;
      font-size: 14px !important;
      padding: 14px 10px 14px 15px;
      &.is-selected {
        display: none;
      }
      @include respond-to(md) {
        font-size: 12px !important;
      }
      @include respond-to(md) {
        letter-spacing: 0.3px !important;
        font-size: 11px !important;
        padding: 10px 8px 10px 15px !important;
      }
    }

    .Select__dropdown-indicator {
      right: 0;
    }

    .TopMenu__hidden-element {
      max-width: 250px;
      white-space: nowrap;
      color: transparent;
      position: absolute;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      white-space: normal;
      @include respond-to(lg) {
        max-width: 155px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .TopMenu__switch-interface-inner {
      .Select__dropdown-indicator {
        border-color: ${colors.primary.hex} !important;

        @include respond-to(md) {
          border-color: ${colors.white.hex} !important;
        }
      }
      .Select__single-value {
        color: ${colors.primary.hex} !important;

        @include respond-to(md) {
          color: ${colors.white.hex} !important;
        }
      }

      .Select__option {
        background-color: ${colors.white.hex};
        color: ${colors.black.hex};

        &--is-selected {
          color: ${colors.secondary.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.25], 0, [colors.gradientEnd.rgb, 0.25], 100)};
        }

        &--is-focused {
          color: ${colors.secondary.hex};
          background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
        }

        &--is-disabled {
          color: ${colors.gray.hex};
          background-color: ${colors.lightGray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
