import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

const TranslatedNavLink = ({ className, id, to, isUpperCase, navLinkId }) => (
  <FormattedMessage id={id}>
    {txt => (
      <NavLink {...{ id: navLinkId }} className={className} exact to={to}>
        {isUpperCase ? txt.toUpperCase() : txt}
        <i />
      </NavLink>
    )}
  </FormattedMessage>
);

TranslatedNavLink.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isUpperCase: PropTypes.bool,
  navLinkId: PropTypes.string,
};

TranslatedNavLink.defaultProps = {
  className: null,
  isUpperCase: false,
  navLinkId: null,
};

export default TranslatedNavLink;
