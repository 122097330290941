import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTheme } from 'common/styling/theme';
import { BasicModal } from 'common/components';

import { ApproveWithdrawalForm } from '..';
import { staticStyles, getDynamicStyles } from './style';

import { getApprovalSteps } from '../../_redux';

const RequestSteps = ({
  groupId,
  approvalSteps,
  approvalStepsAreloaded,
  getApprovalSteps,
  withdrawApprovalSuperConfirm,
  requestStatus,
  initialIndex,
  isRejected,
  history,
  profile,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const isEmptySteps = !approvalSteps.length;

  if (isRejected) {
    history.push(`/backoffice/withdrawals/approve/`);
  }

  const [activeTab, setActiveTab] = useState({
    index: 0,
    isApproved: false,
  });
  useMemo(() => {
    if (approvalStepsAreloaded && !isEmptySteps) {
      setActiveTab({ index: initialIndex, isApproved: approvalSteps[initialIndex].isApproved });
    }
  }, [initialIndex, setActiveTab, approvalStepsAreloaded, approvalSteps, isEmptySteps]);

  useEffect(() => {
    getApprovalSteps(groupId);
  }, [getApprovalSteps, groupId]);

  const isAuditor =
    approvalStepsAreloaded &&
    !isEmptySteps &&
    (approvalSteps[activeTab.index].phase.auditors.map(auditor => auditor.email).includes(profile.email) ||
      approvalSteps[activeTab.index].phase.departments
        .map(department => department.users)
        .flat()
        .map(user => user.email)
        .includes(profile.email));

  const canApprove = withdrawApprovalSuperConfirm || isAuditor;

  const hasRejectedRequest = approvalSteps.find(step => step.isApproved === false);

  const handleStepClick = id => {
    setActiveTab({
      index: id,
      isApproved: approvalSteps[id].isApproved,
    });
  };

  const handleReject = () => {
    toggleModal();
  };

  const groupedStepsByPriority = approvalSteps.reduce((acc, cur) => {
    acc[cur.phase.priority] = [...(acc[cur.phase.priority] || []), cur];
    return acc;
  }, {});

  const getIndexById = step => approvalSteps.findIndex(s => s.id === step.id);

  const getAuditorsFullName = (firstName, lastName) => `${firstName} ${lastName}`;

  return (
    <div className="WithdrawRequestSteps">
      <FormattedMessage id="justWithdrawRequestApproval">
        {txt => <div className="WithdrawRequestSteps__title">{txt}</div>}
      </FormattedMessage>

      <div className="WithdrawRequestSteps__wrapper">
        {approvalStepsAreloaded && !isEmptySteps ? (
          <>
            <Scrollbars className="BasicModal__scroll" autoHeight style={{ width: '100%' }} hideTracksWhenNotNeeded>
              <ul className="WithdrawRequestSteps__list">
                {Object.values(groupedStepsByPriority).map((steps, index) => (
                  <li className="WithdrawRequestSteps__item" key={steps[0].id}>
                    <FormattedMessage id="justStep">
                      {txt => <div className="WithdrawRequestSteps__header">{`${txt} ${index + 1}`}</div>}
                    </FormattedMessage>
                    <div className="WithdrawRequestSteps__btns">
                      {steps.map(step => (
                        <button
                          key={step.id}
                          type="button"
                          className={classNames('WithdrawRequestSteps__status', {
                            success: step.isApproved,
                            error: step.isApproved === false,
                            active: activeTab.index === getIndexById(step),
                          })}
                          onClick={() => handleStepClick(getIndexById(step))}>
                          {`${step.phase.title}`}
                          {!!step.phase.auditors.length && (
                            <FormattedMessage id="justAuditors">
                              {txt => (
                                <span className="WithdrawRequestSteps__auditors">
                                  {`${txt}: ${step.phase.auditors
                                    .map(({ firstName, lastName }) => getAuditorsFullName(firstName, lastName))
                                    .join(', ')}`}
                                </span>
                              )}
                            </FormattedMessage>
                          )}
                          {!!step.phase.departments.length && (
                            <FormattedMessage id="justDepartments">
                              {txt => (
                                <span className="WithdrawRequestSteps__departments">
                                  {`${txt}: ${step.phase.departments.map(department => department.name).join(', ')}`}
                                </span>
                              )}
                            </FormattedMessage>
                          )}
                        </button>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </Scrollbars>

            <div className="WithdrawRequestSteps__reasons">
              <FormattedMessage id="justReasons">{txt => `${txt}:`}</FormattedMessage>
              <ul className="WithdrawRequestSteps__reasons-list">
                {approvalSteps[activeTab.index].phase.conditions
                  .filter(cond => cond.value)
                  .map(({ type, value }, index) => {
                    switch (type) {
                      case 'amount':
                        return (
                          <li className="WithdrawRequestSteps__reasons-item">
                            <FormattedMessage
                              key={`${index}${value}`}
                              id="approvalReasonMoreThan"
                              values={{
                                amount: value,
                              }}
                            />
                          </li>
                        );
                      case 'payment_systems':
                        return (
                          <li className="WithdrawRequestSteps__reasons-item">
                            <FormattedMessage key={`${index}${value}`} id={`status_${type}`} />
                            {`: `}
                            {value.length > 1 ? (
                              value.map((item, index) => <span key={item}>{index === 0 ? item : `, ${item}`}</span>)
                            ) : (
                              <span>{value[0]}</span>
                            )}
                          </li>
                        );
                      case 'choose_payment_systems':
                        return null;
                      default:
                        return (
                          <li className="WithdrawRequestSteps__reasons-item">
                            <FormattedMessage key={`${index}${value}`} id={`status_${type}`} />
                          </li>
                        );
                    }
                  })}
              </ul>
            </div>

            {approvalSteps[activeTab.index].isApproved === null &&
              canApprove &&
              !hasRejectedRequest &&
              approvalSteps[activeTab.index].phase.priority <= approvalSteps[initialIndex].phase.priority &&
              requestStatus !== 'REJECTED' && (
                <div>
                  <div className="WithdrawRequestSteps__content">
                    <ApproveWithdrawalForm
                      groupId={groupId}
                      stepId={approvalSteps[activeTab.index].id}
                      onReject={handleReject}
                    />
                  </div>
                  <BasicModal isOpen={isOpen} captionId="justRejectWithdrawal" onRequestClose={toggleModal}>
                    <ApproveWithdrawalForm
                      inSteps
                      groupId={groupId}
                      stepId={approvalSteps[activeTab.index].id}
                      onReject={handleReject}
                    />
                  </BasicModal>
                </div>
              )}
          </>
        ) : (
          <FormattedMessage id="withdrawAutoApprovedText" />
        )}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

RequestSteps.propTypes = {
  groupId: PropTypes.string.isRequired,
  approvalSteps: PropTypes.array.isRequired,
  approvalStepsAreloaded: PropTypes.bool.isRequired,
  getApprovalSteps: PropTypes.func.isRequired,
  initialIndex: PropTypes.number.isRequired,
  requestStatus: PropTypes.string.isRequired,
  withdrawApprovalSuperConfirm: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  isRejected: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line
  style: PropTypes.object,
  theme: PropTypes.object,
};

RequestSteps.defaultProps = {
  withdrawApprovalSuperConfirm: false,
  style: {},
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => {
      const approvalSteps = state.backoffice.payments.approvalSteps;
      const profile = state.user.profile;
      const withdrawApprovalSuperConfirm = state.user.profile.perms.withdrawApprovalSuperConfirm;
      const auditorEmails = approvalSteps.map(step => step.phase.auditors.map(auditor => auditor.email));
      const stepIndex = approvalSteps.findIndex(step => step.isApproved === null);
      const initialIndex = stepIndex > -1 ? stepIndex : approvalSteps.length - 1;

      return {
        profile,
        withdrawApprovalSuperConfirm,
        approvalSteps,
        approvalStepsAreloaded: state.backoffice.payments.approvalStepsAreloaded,
        initialIndex,
        auditorEmails,
        isRejected: state.backoffice.payments.isRejected,
      };
    },
    {
      getApprovalSteps: groupId => getApprovalSteps.request(groupId),
    }
  )
)(RequestSteps);
export { RequestSteps };
