import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { ChangeLangButton, StatusCell } from 'common/components';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import { logout } from 'auth';
import { stopImpersonate } from '../../_redux/actions';
import { staticStyles, getDynamicStyles } from './style';

const ClientInfo = ({
  kycVerifier,
  availableLangs,
  profile,
  logout,
  stopImpersonate,
  showClientSpecificPart,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <div className="ClientInfo__inner">
      <div className="ClientInfo__image">
        <Gravatar md5={md5(profile.email)} size={150} />
      </div>

      <div className="ClientInfo__status-name">
        <div>
          <strong>{profile.fullName}</strong>
        </div>
        {showClientSpecificPart && (
          <StatusCell statusCode={profile.status === 'verified_new_documents' ? 'verified' : profile.status} noDot />
        )}
      </div>
      {showClientSpecificPart && (
        <div className="ClientInfo__mobile-status-name">
          <StatusCell statusCode={profile.status === 'verified_new_documents' ? 'verified' : profile.status} noDot />
        </div>
      )}

      <div className="ClientInfo__menu">
        <ul>
          <li className="ClientInfo__mobile-fullname-li">
            <div className="ClientInfo__mobile-fullname">{profile.fullName}</div>
          </li>
          {showClientSpecificPart && (
            <>
              <li>
                <FormattedMessage id={profile.status === 'verified' ? 'justProfile' : 'menuVerification'}>
                  {txt => (
                    <NavLink
                      to={profile.needsVerification && kycVerifier !== 'sumsub' ? '/personal_profile' : '/documents'}>
                      {txt}
                    </NavLink>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <div className="ClientInfo__user-id">ID : {profile.id}</div>
              </li>
              <li>
                <div className="ClientInfo__user-security-code">
                  <FormattedMessage id="justSecurityCode">
                    {txt => (
                      <span>
                        {txt}: {profile.securityPin ? profile.securityPin : '------'}
                      </span>
                    )}
                  </FormattedMessage>
                </div>
              </li>
            </>
          )}
          {profile.isImpersonate && (
            <li className="ChangeLangButton__li">
              <FormattedMessage id="impersonateStop">
                {txt => (
                  <button
                    type="button"
                    onClick={() => {
                      stopImpersonate();
                      window.location.href = `/backoffice/users/${profile.id}`;
                    }}>
                    {txt}
                  </button>
                )}
              </FormattedMessage>
            </li>
          )}
          {availableLangs && (
            <li className="ChangeLangButton__li">
              <ChangeLangButton
                className={classNames('ClientInfo__langButton', {
                  ClientInfo__langButton_show: availableLangs.length > 1,
                })}
              />
            </li>
          )}
          <li>
            <FormattedMessage id="menuExit">
              {txt => (
                <button type="button" onClick={() => logout()}>
                  {txt}
                </button>
              )}
            </FormattedMessage>
          </li>
        </ul>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

ClientInfo.propTypes = {
  kycVerifier: PropTypes.string.isRequired,
  availableLangs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  stopImpersonate: PropTypes.func.isRequired,
  showClientSpecificPart: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

ClientInfo.defaultProps = {
  theme: {},
};

const ThemedClientInfo = withTheme()(
  connect(
    state => ({
      profile: state.user.profile,
      kycVerifier: state.interfaceConfig.kycSettings.kyc_verifier,
    }),
    {
      logout,
      stopImpersonate: () => stopImpersonate.request(),
    }
  )(ClientInfo)
);

export default ThemedClientInfo;
export { ClientInfo };
