import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UsersPage__clients {
    display: flex;

    @include respond-to(sm) {
      top: rem(94px);
    }

    .UsersPage__free-clients {
      margin-right: rem(${isRTL ? '30px' : '0'});
      margin-left: rem(${isRTL ? '0' : '30px'});
    }

    .UsersPage__client-icon {
      display: flex;
      :global(svg) {
        width: 16px;
        height: 16px;
      }

      .UsersPage__clients-count {
        font-size: 16px;
        margin-right: rem(${isRTL ? '10px' : '0'});
        margin-left: rem(${isRTL ? '0' : '10px'});
      }
    }

    .UsersPage__free-clients-text {
      text-transform: lowercase;
    }
  }

  .UsersPage__clients--withoutSearchPanel {
    top: 0;
  }

  .UsersPage__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(30px);

    .UsersPage__create-button {
      margin-right: rem(${isRTL ? '0' : '15px'});
      margin-left: rem(${isRTL ? '15px' : '0'});
    }

    .UsersPage__export-buttons {
      margin-left: rem(${isRTL ? '0' : 'auto'});
      margin-right: rem(${isRTL ? 'auto' : '0'});

      :global(.DefaultButton:first-child) {
        margin-right: rem(${isRTL ? '0' : '15px'});
        margin-left: rem(${isRTL ? '15px' : '0'});
      }
    }
    .UsersPage__import-export-selects {
      display: flex;
      gap: rem(15px);
    }
    .UsersPage__import-export-select {
      width: 150px;

      .BaseSelectField {
        margin-bottom: 0;
        &__label {
          font-size: rem(15px);
          line-height: rem(22px);
          font-weight: 500;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .Select__control {
        min-height: 100% !important;
      }
    }

    @include respond-to(md) {
      flex-direction: column;
      align-items: flex-start;

      > *:not(:last-child) {
        margin-bottom: rem(20px);
      }

      .UsersPage__export-buttons {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UsersPage__client-icon {
      :global(svg) {
        :global(g) {
          fill: ${colors.gray.hex};
        }
      }
    }
    .UsersPage__free-clients-text {
      color: ${colors.gray.hex};
    }
    .UsersPage__import-export-select {
      .BaseSelectField {
        .Select__control {
          border-color: ${colors.primary.hex} !important;
          &::after {
            border-color: ${colors.primary.hex};
          }
        }

        &__label {
          color: ${colors.primary.hex};
        }
      }
    }
  `;
};
export { staticStyles, getDynamicStyles };
