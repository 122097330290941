import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import queryString from 'query-string';

import { PageLayout, NoItemsPanel, PageContentPreloader, SearchPanel, TransfersTable } from 'common/components';

import noPaymentsImage from 'common/images/noPayments.svg';
import { getInternalTransfers } from '../_redux';

const InternalTransfersPage = ({
  internalTransfers,
  internalTransfersAreLoaded,
  getInternalTransfers,
  history,
  location,
  intl,
  features,
}) => {
  const query = { page_size: 10, ...queryString.parse(location.search) };
  /* eslint-disable */
  useEffect(() => {
    getInternalTransfers({ search: query });
  }, [getInternalTransfers]);
  /* eslint-enable */

  const getReloadItems = () => {
    getInternalTransfers({ search: query });
  };

  const handleRowClick = id => history.push(`/backoffice/transfers/${id}/`);
  const hasPaymentOperationManagers = features.includes('paymentOperationManager');

  const filters = [
    {
      title: 'justStatus',
      accessor: 'status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'IN_PROGRESS', label: intl.formatMessage({ id: 'status_in_progress' }) },
        { value: 'WAITING_FOR_APPROVAL', label: intl.formatMessage({ id: 'status_waiting_for_approval' }) },
        { value: 'COMPLETED', label: intl.formatMessage({ id: 'status_completed' }) },
        { value: 'CANCELED', label: intl.formatMessage({ id: 'status_canceled' }) },
      ],
    },
    {
      title: 'boTransferType',
      accessor: 'transferType',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'INNER', label: intl.formatMessage({ id: 'boInnerTransfer' }) },
        { value: 'TO_TRADER', label: intl.formatMessage({ id: 'boAnotherTrader' }) },
      ],
    },
  ];

  return (
    <PageLayout
      captionSlug="boInternalTransfers"
      showReload
      onReloadClick={getReloadItems}
      reloading={!internalTransfersAreLoaded}>
      <PageContentPreloader ready={internalTransfersAreLoaded} type="smallLines" firstLaunchOnly>
        {!internalTransfers.count && !location.search ? (
          ''
        ) : (
          <SearchPanel
            location={location}
            history={history}
            getList={getInternalTransfers}
            filters={filters}
            params={{ search: query }}
          />
        )}
        {internalTransfers.count ? (
          <TransfersTable
            data={internalTransfers}
            loading={!internalTransfersAreLoaded}
            location={location}
            history={history}
            getList={getInternalTransfers}
            onRowClick={handleRowClick}
            params={{ search: query }}
            hasPaymentOperationManagers={hasPaymentOperationManagers}
          />
        ) : (
          <NoItemsPanel
            icon={noPaymentsImage}
            captionSlug={location.search ? 'noTransfersMatchingTheParameters' : 'noInternalTransfers'}
          />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

InternalTransfersPage.propTypes = {
  internalTransfers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  internalTransfersAreLoaded: PropTypes.bool.isRequired,
  getInternalTransfers: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      internalTransfers: state.backoffice.transfers.internalTransfers,
      internalTransfersAreLoaded: state.backoffice.transfers.internalTransfersAreLoaded,
      features: state.interfaceConfig.features,
    }),
    {
      getInternalTransfers: data => getInternalTransfers.request(data),
    }
  )
)(InternalTransfersPage);
