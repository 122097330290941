import axios from 'axios';

const validateStatus = status => status < 500;

export const makeDeposit = data =>
  axios.post('/payments/v2/deposit/make/', {
    ...data,
  });

export const makeWithdraw = data =>
  axios.post('/payments/v2/withdraw/make/', {
    ...data,
  });

export const finishPayment = data =>
  axios.post('/payments/v2/finish/', {
    ...data,
  });
export const sendRequisites = (data, id) =>
  axios.put(`/payments/v2/requisites/${id}/`, {
    ...data,
  });

export const confirmPaymentsOperation = ({ confirmationId, paymentId }) =>
  axios.post(
    `/payments/v2/${paymentId}/verify/${confirmationId}/`,
    {},
    {
      validateStatus: status => status < 300 || status === 403 || status === 404,
    }
  );

export const resendPaymentConfirmationMessage = id => axios.post(`/payments/request/${id}/resend_verify/`);

export const getPayments = id => axios.get(`/payments/request/${id}/`, { validateStatus });
export const getPaymentsInvoice = id => axios.get(`/payments/request/${id}/invoice/`, { responseType: 'blob' });

export const getPaymentsList = search => axios.get('/payments/request/', { params: { ...search } });

export const getInOutList = search => axios.get('/payments/request/without_wallet/', { params: { ...search } });

export const getAvailableLpas = () => axios.get('/lpa/user_list/');

export const makePaymentLpa = params => axios.post(`/lpa/`, { ...params });

export const getLpaClientDeals = search => axios.get(`lpa/`, { params: { ...search } });

export const getLpaAgentDeals = search => axios.get(`lpa/my_deals/`, { params: { ...search } });

export const getLpaDeal = id => axios.get(`/lpa/${id}/`);

export const cancelWithdrawal = id => axios.post(`/payments/request/${id}/cancel/`);

export const userGetEditHistory = id => axios.get(`/payments/request/${id}/edit_history/`);

export const uploadPaymentProof = (id, file) => {
  const data = new FormData();
  data.append('file', file[0]);
  const config = { headers: { 'content-type': 'multipart/form-data' }, validateStatus: status => status <= 403 };
  return axios.post(`/payments/request/${id}/proof/upload/`, data, config);
};
