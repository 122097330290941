import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TradeOperationsTable {
    :global(.ReactDataTable__wrapper) {
      overflow-x: scroll;
      :global(.ReactDataTable) {
        tr {
          grid-template-columns: minmax(110px, 1.2fr) minmax(150px, 1.5fr) minmax(90px, 1fr) repeat(
              10,
              minmax(80px, 1fr)
            );
        }
        .ReactDataTable__tbody-cell {
          :global(span) {
            font-size: 15px;
          }

          :global(.time) {
            display: block;
            color: #777777;
          }
        }
      }
    }

    .ReactDataTable {
      &__thead-row {
        display: grid !important;
        grid-template-columns:
          minmax(110px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minmax(230px, 1fr) !important;
      }

      &__thead-cell {
        text-overflow: clip !important;
      }

      &__tbody-row {
        display: grid !important;
        grid-template-columns:
          minmax(110px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minmax(230px, 1fr) !important;

        &:last-child {
          margin: 2px 4px 0 4px;
        }
      }

      &__tbody-cell {
        text-overflow: clip !important;

        &:nth-of-type(3) {
          :global(span) {
            font-size: 13px !important;
          }
        }
      }

      &__tfoot-row {
        display: grid !important;
        grid-template-columns:
          minmax(110px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(150px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(90px, 1fr)
          minmax(110px, 1fr)
          minmax(110px, 1fr)
          minmax(230px, 1fr) !important;
        margin: 0 4px 0 4px;
      }

      &__tfoot-cell {
        .summary {
          &__order {
            font-size: 20px;
            font-weight: 500;
          }

          &__lots,
          &__commission,
          &__swap,
          &__profit {
            text-align: left;

            span {
              font-size: 14px;
              color: #777777;
            }

            div {
              margin: 3px 0 0 0;
            }
          }
        }
      }
    }
  }

  .TradeOperationsTable__binary {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        tr {
          grid-template-columns: minmax(110px, 1.2fr) minmax(150px, 1.5fr) minmax(90px, 1fr) repeat(
              6,
              minmax(80px, 1fr)
            );
        }
      }
    }
  }
`;

export default staticStyles;
