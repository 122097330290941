import React from 'react';
import { colorType, withTheme } from '../styling/theme';

const DownloadIcon = ({ grayColor }) => (
  <svg className="notFillIcon" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.451 5.62509L10.9987 8.208L10.9987 0.525053C10.9987 0.235074 10.7755 3.56276e-07 10.5002 3.32211e-07C10.225 3.08146e-07 10.0018 0.235074 10.0018 0.525053L10.0018 8.208L7.54989 5.62509C7.42415 5.49263 7.24088 5.4409 7.06912 5.48938C6.89735 5.53786 6.76319 5.67919 6.71717 5.86013C6.67114 6.04107 6.72025 6.23413 6.84599 6.36659L10.1526 9.8455C10.2454 9.94351 10.3711 9.99902 10.5025 10C10.6349 9.99973 10.7616 9.94317 10.8539 9.84315L14.1549 6.36581C14.2968 6.21567 14.3392 5.99034 14.2623 5.79451C14.1854 5.59869 14.0043 5.47077 13.8031 5.4702C13.6706 5.47054 13.5439 5.52617 13.451 5.62509Z"
      fill={grayColor.hex}
    />
    <path
      d="M20 8V15.5C20 16.3284 19.3284 17 18.5 17H2.5C1.67157 17 1 16.3284 1 15.5V8"
      stroke={grayColor.hex}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

DownloadIcon.propTypes = {
  grayColor: colorType,
};

DownloadIcon.defaultProps = {
  grayColor: {},
};

export default withTheme(({ colors: { gray } }) => ({
  grayColor: gray,
}))(DownloadIcon);
