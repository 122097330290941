import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.NewCRMTableNoQuery__Cell--Tbody) {
    border-top: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }

  .MyTasks {
    &__title-date-wrap {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      margin: 0 0 20px 0;
    }

    &__title {
      margin: 0 20px 0 0;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;
      color: #0f0f0f;
    }

    &__date {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #0f0f0f;
    }

    &__table-overdue {
      height: 300px;
    }

    &__table-today {
      height: 300px;
    }

    &__table-tomorrow {
      margin: 0 0 35px 0;
      height: 300px;
    }
  }
`;

export { staticStyles };
