import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DefaultButton from '../../DefaultButton';
import BasicModal from '../BasicModal';
import { staticStyles } from './style';

const SimpleModal = ({ caption, content, description, closeHandler, actionButton, cancelButton }) => {
  let isContent;
  if (content) {
    if (content.text) {
      isContent = content.text;
    } else {
      isContent = <FormattedMessage id={content.id} values={content.values} />;
    }
  } else {
    isContent = false;
  }

  const modalDescription = description?.id !== '' && <FormattedMessage id={description.id} />;

  return (
    <BasicModal
      isOpen
      caption={caption.text}
      captionId={caption.id}
      captionValues={caption.values}
      onRequestClose={closeHandler}>
      <div>{isContent}</div>
      <div className="SimpleModal__description">{modalDescription}</div>
      <div className="SimpleModal__buttons-inner">
        {actionButton && (
          <div className="SimpleModal__button-inner">
            <DefaultButton
              type="button"
              textId={actionButton.textId}
              butColor={actionButton.type}
              onClick={() => {
                actionButton.handler();
                closeHandler();
              }}
            />
          </div>
        )}

        {cancelButton && (
          <div className="SimpleModal__button-inner">
            <DefaultButton type="button" textId={cancelButton.textId} onClick={closeHandler} filled />
          </div>
        )}
      </div>
      <style jsx>{staticStyles}</style>
    </BasicModal>
  );
};

SimpleModal.propTypes = {
  caption: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.object,
  }).isRequired,
  closeHandler: PropTypes.func.isRequired,
  content: PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.object,
  }),
  description: PropTypes.shape({
    id: PropTypes.string,
  }),
  actionButton: PropTypes.shape({
    textId: PropTypes.string,
    type: PropTypes.string,
    handler: PropTypes.func.isRequired,
  }),
  cancelButton: PropTypes.shape({
    textId: PropTypes.string,
  }),
};

SimpleModal.defaultProps = {
  content: {
    text: '',
    id: '',
    values: {},
  },
  description: {
    id: '',
  },
  actionButton: {
    textId: '',
    type: '',
  },
  cancelButton: {
    textId: '',
  },
};

export default SimpleModal;
