import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import ArrowsDown from './icons/ArrowsDown.svg';
import ArrowsUp from './icons/ArrowsUp.svg';
import NoSortArrows from './icons/NoSortArrows.svg';

import { staticStyles, getDynamicStyles } from './style';

const SortIcon = ({ currentOrdering, accessor }) => {
  if (currentOrdering === `-${accessor}`) {
    return <img src={ArrowsDown} alt="SortDown" />;
  }
  if (currentOrdering === accessor) {
    return <img src={ArrowsUp} alt="SortUp" />;
  }

  return <img src={NoSortArrows} alt="NoSort" />;
};

const getManagerReactionClassAfterfix = managerReaction => {
  if (managerReaction === 'Not Success') {
    return 'notSuccess';
  }

  if (managerReaction === 'Success') {
    return 'success';
  }

  if (managerReaction === 'Bad Client') {
    return 'badClient';
  }
  return '';
};

const NewCRMTableNoQuery = ({ columns, data, ordering, setOrdering, renderRowSubComponent, onRowClick, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme, columns) : ` `;

  const handleColumnSortChange = columnAccessor => {
    let newOrdering = null;

    if (ordering !== `-${columnAccessor}` && ordering !== columnAccessor) {
      newOrdering = `-${columnAccessor}`;
    }

    if (ordering === `-${columnAccessor}`) {
      newOrdering = columnAccessor;
    }

    setOrdering(newOrdering);
  };

  return (
    <div className="NewCRMTableNoQuery__wrapper">
      <table className="NewCRMTableNoQuery">
        <thead className="NewCRMTableNoQuery__Head">
          <tr className="NewCRMTableNoQuery__Head-Row">
            {columns.map(({ Header, accessor, isSortable, blueBg }, ind) => (
              <th
                key={`NewCRMTableNoQuery-header-${ind}-${accessor}`}
                className={classNames('NewCRMTableNoQuery__Cell NewCRMTableNoQuery__Cell--Thead', {
                  'NewCRMTableNoQuery__Cell--Sortable': isSortable,
                  'NewCRMTableNoQuery__Cell--BlueBg': blueBg,
                })}
                onClick={() => {
                  if (isSortable) {
                    handleColumnSortChange(accessor);
                  }
                }}>
                <div className="NewCRMTableNoQuery__Cell-Inner">
                  {typeof Header === 'string' ? <FM id={Header} /> : Header()}
                  {isSortable && <SortIcon currentOrdering={ordering} accessor={accessor} />}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="NewCRMTableNoQuery__Body">
          {data.map(rowData => {
            if (rowData.isExpanded) {
              return (
                <>
                  <tr
                    className={`NewCRMTableNoQuery__Body-Row NewCRMTableNoQuery__Body-Row--${getManagerReactionClassAfterfix(
                      rowData.managerReaction || rowData.lastTask?.managerReaction
                    )}`}
                    key={`Contact-${rowData.id}-info`}
                    onClick={e => {
                      e.stopPropagation();
                      onRowClick({ rowData });
                    }}>
                    {columns.map(({ Cell, accessor }, ind) => (
                      <td
                        className={classNames('NewCRMTableNoQuery__Cell NewCRMTableNoQuery__Cell--Tbody')}
                        key={`NewCRMTableNoQuery-body-${accessor}-${ind}`}>
                        {rowData && Cell({ rowData, isExpanded: rowData.isExpanded })}
                      </td>
                    ))}
                  </tr>
                  {renderRowSubComponent({ rowData })}
                </>
              );
            }

            return (
              <tr
                onClick={e => {
                  e.stopPropagation();
                  onRowClick({ rowData });
                }}
                className={`NewCRMTableNoQuery__Body-Row NewCRMTableNoQuery__Body-Row--${getManagerReactionClassAfterfix(
                  rowData.managerReaction || rowData.lastTask?.managerReaction
                )}`}
                key={`Contact-${rowData.id}-info`}>
                {columns.map(({ Cell, accessor, overflowText }, ind) => (
                  <td
                    className={classNames('NewCRMTableNoQuery__Cell NewCRMTableNoQuery__Cell--Tbody')}
                    key={`NewCRMTableNoQuery-body-${accessor}-${ind}`}>
                    {overflowText ? (
                      <div className="NewCRMTableNoQuery__Inner-Cell-OverflowText">{rowData && Cell({ rowData })}</div>
                    ) : (
                      rowData && Cell({ rowData })
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

NewCRMTableNoQuery.propTypes = {
  ordering: PropTypes.string,
  setOrdering: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  renderRowSubComponent: PropTypes.func,
  onRowClick: PropTypes.func,
  theme: PropTypes.object,
};

NewCRMTableNoQuery.defaultProps = {
  renderRowSubComponent: null,
  ordering: null,
  onRowClick: () => {},
  data: [],
  theme: {},
};

export default compose(withTheme(), withRouter)(NewCRMTableNoQuery);
export { NewCRMTableNoQuery };
