import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestmentsTable {
    position: relative;

    :global(.TableLoader) {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #ffffff;
      z-index: 2;
    }

    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        table-layout: fixed;
        width: 100%;

        :global(.ReactDataTable__tbody-row) {
          margin-left: 0;
        }

        :global(.ReactDataTable__thead-row) {
          margin: 0;

          :global(th) {
            :global(span) {
              white-space: normal;
            }
          }
        }

        :global(.ReactDataTable__tbody-cell) {
          position: relative;
          overflow: visible;

          :global(.TableActions) {
            text-align: ${isRTL ? 'left' : 'center'};
          }

          :global(.TableActions__btns) {
            min-width: 200px;
            max-width: 200px;
            right: -20px;
          }

          :global(.time) {
            display: block;
            color: #777777;
            text-align: center;
          }

          :global(.MoneyCell__inner) {
            :global(span) {
              font-size: rem(13px) !important;
            }
          }

          :global(.title) {
            display: block;
            font-size: rem(15px);
            font-weight: 500;
            color: #000;
            margin-bottom: rem(3px);
          }

          :global(.symbols) {
            display: block;
            color: #777777;
            font-size: rem(13px);
          }

          :global(.editValue) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          &:global(span) {
            text-overflow: ellipsis;
            overflow: hidden;

            @media only screen and (max-width: 1220px) {
              font-size: rem(13px);
            }
          }
        }

        :global(.ReactDataTable__tbody-cell):last-child {
          @media only screen and (min-width: 788px) {
            padding-left: 0px;
          }
        }
      }
    }

    :global(.ResponsiveTable) {
      :global(.ResponsiveTable__thead) {
        @include respond-to(md) {
          padding: rem(10px 0 10px 10px);
        }
      }

      :global(.ResponsiveTable__row-item:first-child) {
        @include respond-to(md) {
          justify-content: flex-start;
        }
      }

      :global(.ResponsiveTable__row-item) {
        @include respond-to(sm) {
          max-width: 50%;
        }
      }
      :global(.ResponsiveTable__details-item) {
        padding-left: rem(10px);

        :global(.RammInvestmentsTable__btns) {
          width: 90%;
        }
      }

      :global(.TableActions) {
        @include respond-to(sm) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      :global(.TableActions__btns) {
        width: 260px;
        right: 0%;
        max-height: 110px;
        overflow-y: scroll;
        top: auto;
        bottom: 20px;
      }

      :global(.InvestmentsTable__title) {
        width: 100%;
        :global(.title) {
          white-space: normal;
        }
        :global(.masterAccount) {
          white-space: normal;
        }
        @include respond-to(sm) {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }

      :global(.MoneyCell__inner) {
        @include respond-to(sm) {
          height: auto;
        }
      }
    }

    :global(&__btns) {
      display: flex;
      gap: 4px;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      :global(.ActionsButton) {
        margin: 0;

        :global(svg) {
          width: 25px;
          height: 25px;
        }
      }

      :global(.Tooltip) {
        background: #ffffff !important;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;

        :global(pre) {
          color: #000;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  .InvestmentsTable-active {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns:
          minmax(200px, 2fr)
          repeat(4, minmax(100px, 1fr))
          minmax(100px, 0.5fr);
      }
    }
  }

  .InvestmentsTable-active--NEW {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns:
          minmax(200px, 2fr)
          repeat(2, minmax(200px, 2fr))
          minmax(100px, 0.5fr);
      }
    }
  }

  .InvestmentsTable-closed {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: repeat(6, minmax(100px, 1fr)) minmax(200px, 1fr);

        @media only screen and (max-width: 1220px) {
          grid-template-columns:
            minmax(100px, 0.5fr) minmax(100px, 2fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(70px, 0.5fr)
            minmax(70px, 0.5fr)
            minmax(140px, 1.5fr);
        }
      }
    }
  }

  .InvestmentsTable-requests {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns:
          minmax(50px, 0.5fr)
          repeat(3, minmax(100px, 1fr))
          minmax(100px, 1.5fr)
          minmax(100px, 1fr)
          minmax(70px, 1fr)
          minmax(100px, 1fr);
      }
    }
  }
`;

export { staticStyles };
