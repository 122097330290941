import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { PaginationTable } from 'common/components';

import { withTheme } from 'common/styling/theme';

import ClientsCellWithDropDown from './ClientsCellWithDropDown';
import { staticStyles, getDynamicStyles } from './style';

const PartnersTable = ({ data, onRowClick, getList, loading, history, location, params, theme }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: () => null,
      id: 'avatar',
      Cell: ({
        row: {
          original: { email },
        },
      }) => <Gravatar md5={md5(email)} size={35} />,
      isPreviewCell: true,
    },
    {
      Header: 'justFullNameSlashIb',
      accessor: 'full_name',
      Cell: ({
        row: {
          original: { fullName, ibAccounts },
        },
      }) => {
        return (
          <div className="FullNameAndIbCell">
            <span className="FullNameAndIbCell__FullName">{fullName}</span>
            <div className="FullNameAndIbCell__IbAccounts">
              {ibAccounts &&
                ibAccounts.length &&
                ibAccounts.map(ibAccount => <span className="FullNameAndIbCell__IbAccount">{ibAccount}</span>)}
            </div>
          </div>
        );
      },
      isPreviewCell: true,
    },
    { Header: 'justEmail', accessor: 'email', isPreviewCell: true },
    {
      Header: 'justPhone',
      accessor: 'phone',
      Cell: ({
        row: {
          original: { phone },
        },
      }) => phone,
    },
    {
      Header: 'justMasterIb',
      accessor: 'masterIb',
      Cell: ({
        row: {
          original: { masterIb, partnerAccount },
        },
      }) => (
        <div className="PartnersTable__masterIb">
          {masterIb ? (
            <>
              <span className="PartnersTable__masterIb-caption">{masterIb}</span>
              <span className="PartnersTable__masterIb-name">{partnerAccount}</span>
            </>
          ) : (
            '一'
          )}
        </div>
      ),
    },
    {
      Header: 'boPartnerClients',
      accessor: 'clients',
      Cell: ({
        row: {
          original: { clients },
        },
      }) => clients,
    },
    {
      Header: () => null,
      id: 'clients-list',
      Cell: ({
        row: {
          original: { ibAccounts },
        },
      }) => <ClientsCellWithDropDown ibAccounts={ibAccounts} />,
    },
  ];
  /* eslint-enable */

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="PartnersTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getList={getList}
        location={location}
        history={history}
        params={params}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        isNewTable
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PartnersTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

PartnersTable.defaultProps = {
  theme: {},
};

export default withTheme()(PartnersTable);
export { PartnersTable };
