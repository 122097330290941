import axios from 'axios';

export const getBonusesInfoList = search => axios.get(`/backoffice/bonuses/info/list/`, { params: { ...search } });

export const getBonusInfo = id => axios.get(`/backoffice/bonuses/info/${id}/`);

export const getBonusTypes = () => axios.get(`/backoffice/bonuses/types/`);

export const createBonusInfo = payload => axios.post(`/backoffice/bonuses/info/create/`, { ...payload });

export const deleteBonusInfo = id => axios.delete(`/backoffice/bonuses/info/${id}/`);

export const editBonusInfo = (id, payload) => axios.put(`/backoffice/bonuses/info/${id}/`, { ...payload });
