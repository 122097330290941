import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.StatusCell--Open) {
    color: red;
  }
  :global(.StatusCell--Closed) {
    color: green;
  }

  .MyTasksTable {
    .BaseTable {
      &__thead {
      }
      &__thead-row {
      }

      &__thead-cell {
        &:nth-of-type(1) {
          position: sticky !important;
          left: 0;
          top: 0;
          z-index: 1;
          background: #ffffff !important;
          max-width: 40px !important;
          min-width: 40px !important;
          justify-content: flex-start !important;
        }

        &:nth-of-type(2) {
          position: sticky !important;
          left: 23px;
          top: 0;
          z-index: 1;
          min-width: 245px;

          @include respond-to(sm) {
            min-width: 150px;
          }
        }

        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6) {
          min-width: 150px;
        }

        &:nth-of-type(7) {
          min-width: 400px;

          @include respond-to(sm) {
            min-width: 270px;
          }
        }
      }
    }

    .BaseTable__tbody {
      .UserCell {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-right: 5px;

        .name-status {
          position: absolute;
          left: 0;
          width: 6px;
          height: 100%;
          background: transparent;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;

          &[data-status='green'] {
            background: #04eb84;
          }

          &[data-status='grey'] {
            background: #c2c7ce;
          }

          &[data-status='blue'] {
            background: #ceecf9;
          }

          &[data-status='yellow'] {
            background: #fdff89;
          }
        }

        .name {
          display: flex;
          align-items: center;
        }
      }

      .BaseTable__tbody-row {
        cursor: default;

        .BaseTable__tbody-cell {
          overflow: visible;
          padding: 0;

          .UserCell {
            &:before {
              left: 0;
            }
          }

          .User__balance {
            :global(.MoneyCell__inner) {
              height: auto;
              display: flex;

              :global(span) {
                font-size: rem(14px) !important;
                padding: 0 !important;
              }
            }
          }

          &:nth-of-type(1) {
            position: sticky;
            left: 0;
            top: 0;
            z-index: 1;
            background: #ffffff;
            max-width: 30px !important;
            min-width: 30px !important;
            justify-content: flex-start;
          }

          &:nth-of-type(2) {
            position: sticky;
            left: 23px;
            top: 0;
            z-index: 1;
            background: #ffffff;
            min-width: 245px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            @include respond-to(sm) {
              min-width: 150px;
            }
          }

          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            min-width: 150px;
          }

          &:nth-of-type(7) {
            position: relative;
            min-width: 400px;

            @include respond-to(sm) {
              min-width: 270px;
            }
          }

          .last-comment-wrap {
            position: relative;
            width: 100%;
            padding: 4px 42px 4px 10px;
          }

          .last-comment {
            position: relative;
            width: 100%;
          }

          .last-comment-max {
            display: none;
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% - 31px);
            min-height: calc(100% + 2px);
            display: flex;
            align-items: center;
            opacity: 0;
            padding: 4px 10px 4px 10px;
          }

          .last-comment-wrap:hover {
            .last-comment-max {
              opacity: 1;
              background: #ebf7fd;
              border: 1px solid #9dd9f3;
            }
          }

          .last-comment-icon {
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        span {
          max-width: calc(100% - 32px);
          padding: rem(5px 10px);
        }
      }
    }

    .BaseTable__thead-row {
      .BaseTable__thead-cell {
        &:nth-last-of-type(-n + 2) {
          background: transparent;
          cursor: default !important;

          .BaseTable__arrow {
            display: none;
          }
        }
      }
    }

    :global(.NewCRMTableNoQuery__Head-Row),
    :global(.NewCRMTableNoQuery__Body-Row) {
      display: grid;
      grid-template-columns: 1.5fr 1.7fr 1.3fr 1.5fr 2.4fr 1fr 1fr 1fr 1fr 2fr 80px;
    }
  }
  :global(.NewCRMTableNoQuery__wrapper) {
    @include respond-to(xl) {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #0aa1e2;
      }
    }
  }

  :global(.NewCRMTableNoQuery) {
    @include respond-to(xl) {
      width: 1539px;
    }
  }

  :global(.NewCRMTableNoQuery__Body-Row) {
    :global(.NewCRMTableNoQuery__Cell--Tbody):nth-child(1):before {
      background: #c2c7ce;
    }
  }
  :global(.NewCRMTableNoQuery__Body-Row--success) {
    :global(.NewCRMTableNoQuery__Cell--Tbody):nth-child(1):before {
      background: #04eb84;
    }
  }
  :global(.NewCRMTableNoQuery__Body-Row--notSuccess) {
    :global(.NewCRMTableNoQuery__Cell--Tbody):nth-child(1):before {
      background: #fdff89;
    }
  }
  :global(.NewCRMTableNoQuery__Body-Row--badClient) {
    :global(.NewCRMTableNoQuery__Cell--Tbody):nth-child(1):before {
      background: #ff4085;
    }
  }

  :global(.NewCRMTableNoQuery__Cell--Tbody):nth-child(1):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: 0;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
