import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnersTable {
    .FullNameAndIbCell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include respond-to(sm) {
        align-items: center;
        justify-content: center;
      }
      width: 100%;
      padding-left: 10px;
      gap: 5px;
      &__IbAccounts {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    :global(.ReactDataTable) {
      tr {
        grid-template-columns: minmax(50px, 0.5fr) repeat(4, minmax(100px, 2fr)) repeat(2, minmax(100px, 1fr));
      }
    }

    :global(img) {
      width: 35px;
      min-width: 35px;
      border-radius: 50%;
    }

    :global(.PartnersTable__masterIb) {
      width: 100%;
      display: flex;
      flex-direction: column;

      :global(.PartnersTable__masterIb-name) {
        margin-top: rem(5px);
      }
    }

    .ResponsiveTable {
      .ResponsiveTable__thead {
        min-width: 420px !important;

        span {
          flex: 60;
          text-align: center;
          padding: 5px 0;

          &:first-child {
            padding-left: 59px;
            flex: 1;
            opacity: 0;
          }

          &:nth-of-type(3) {
            text-align: left;
          }

          &:last-child {
            padding-right: 28px;
            flex: 1;
          }
        }
      }

      .ResponsiveTable__tfoot {
        .ResponsiveTable__row-details {
          &:after {
            display: none !important;
          }
        }
      }

      .ResponsiveTable__row-details {
        &:after {
          display: none !important;
        }
      }

      .ResponsiveTable__detailed-caption:hover {
        color: green;
      }
    }

    .ResponsiveTable {
      .ResponsiveTable__tbody {
        min-width: 420px !important;

        .ResponsiveTable__row-item {
          flex: 60;
          display: flex;
          align-items: center;
          justify-content: center;

          &:first-child {
            padding-left: rem(20px);
            padding-right: 5px;
            flex: 1;
          }
        }
        .ResponsiveTable__row-preview {
          padding: 9px 30px 9px 9px;
          overflow: visible !important;
          border: none !important;
          outline: none !important;

          &:before {
            content: none;
          }

          .ResponsiveTable__row-item {
            &:nth-of-type(1) {
              @include respond-to(sm) {
                padding: 0 0 0 0;
              }
            }

            &:nth-of-type(3) {
              overflow: hidden;
              text-align: left;
              justify-content: flex-start;
            }
          }
          .ResponsiveTable__preview-main {
            height: max-content !important;
          }
        }

        .ResponsiveTable__row-details {
          overflow: visible !important;
          border: none !important;
          outline: none !important;

          &:before {
            content: none;
          }

          .ResponsiveTable__details-item {
            padding-left: 22%;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PartnersTable {
      :global(.PartnersTable__masterIb-name) {
        color: ${colors.gray.hex};
      }
      :global(.FullNameAndIbCell__IbAccount) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
