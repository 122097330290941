import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.NewCRMTableNoQuery__wrapper) {
    position: relative;
    width: 100%;
  }
  :global(.NewCRMTableNoQuery) {
    position: relative;
    display: grid;
    grid-template-rows: 40px 1fr;
  }

  :global(.NewCRMTableNoQuery__Cell) {
    position: relative;
    min-width: 100%;
    weight: 100%;
    height: 30px;
  }

  :global(.NewCRMTableNoQuery__Cell--Tbody) {
    position: relative;
    padding: 6px;
  }
  :global(.NewCRMTableNoQuery__Cell--Tbody):not(:first-child) {
    border-top: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
  }
  :global(.NewCRMTableNoQuery__Cell--Tbody):last-child {
    border-right: 1px solid #e9e9e9;
  }

  :global(.NewCRMTableNoQuery__Cell--Thead) {
    position: relative;
    line-height: 100%;
    height: 40px;
  }

  :global(.NewCRMTableNoQuery__Cell--BlueBg) {
    background: #f8faff;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    margin: 0 0.5px 0 0.5px;
  }
  :global(.NewCRMTableNoQuery__Head-Row),
  :global(.NewCRMTableNoQuery__Body-Row) {
    position: relative;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }

  :global(.NewCRMTableNoQuery__Cell-Inner) {
    height: 100%;
    min-width: 100%;
    weight: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 7px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #696969;
  }
  :global(.NewCRMTableNoQuery__Body-Row) {
    &:last-child {
      :global(.NewCRMTableNoQuery__Cell--Tbody):not(:first-child) {
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }

  :global(.NewCRMTableNoQuery__Inner-Cell-OverflowText) {
    width: 100%;
    z-index: 10000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    &:hover {
      position: absolute;
      left: 0;
      top: 0;
      padding: 8px 9px 9px 11px;
      min-height: 30px;
      min-width: 100%;
      background: #e9f6fc;
      border: 1px solid #0aa1e2;
      overflow-wrap: break-word;
      z-index: 10000;
      white-space: normal;
      height: max-content;
    }
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
