import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .IssuesTable {
    :global(.ReactDataTable) {
      :global(tr) {
        grid-template-columns: minmax(100px, 0.2fr) minmax(150px, 0.3fr) minmax(200px, 1fr) minmax(100px, 0.4fr) minmax(
            100px,
            0.4fr
          ) minmax(150px, 0.3fr);
      }
    }
  }
`;

export { staticStyles };
