import css from 'styled-jsx/css';

const staticStyles = css.global`
  :global(.BasicModal__inner) {
    :global(h2) {
      text-align: center;
    }
  }
  .CreateAccountPage__created {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      color: #777777;
    }

    :global(.DefaultButton) {
      margin: auto;
      min-width: 250px !important;

      &:last-child {
        text-transform: lowercase;
        color: #777777;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        margin-left: 5px;
        border-bottom: 1px solid !important;
        border-radius: 0;
        min-width: auto !important;
      }
    }
  }

  .CreateAccountPage__link {
    text-align: center;
    margin-top: 25px;
    color: #777777;
    font-size: 15px;
    line-height: 20px;

    & > span {
      display: flex;
      justify-content: center;
    }
  }
`;

export default staticStyles;
