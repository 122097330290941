import queryString from 'query-string';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLayout, PanelTabs } from 'common/components';
import { WalletOperation, InternalTransfer } from '../_components';

const CommonInternalTransferPage = ({ addons, transfersEnabled }) => {
  const history = useHistory();
  const location = useLocation();
  const depositTitleNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'swayCapitalDeposit' : 'justDeposit';
  const defaultTabSlug = addons.includes('walletAccount') ? 'deposit' : 'transfer';
  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : defaultTabSlug),
    [location.search, defaultTabSlug]
  );
  const { account } = useMemo(() => queryString.parse(location.search), [location.search]);

  return (
    <PageLayout captionSlug="clientInternalTransfer" showReload={false}>
      <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
        {addons.includes('walletAccount') && (
          <WalletOperation
            textId={depositTitleNew}
            slug="deposit"
            id="deposit"
            initialValues={{ recipientAccount: +account }}
          />
        )}
        {addons.includes('walletAccount') && (
          <WalletOperation
            textId="justWithdraw"
            slug="withdraw"
            id="withdraw"
            initialValues={{ senderAccount: +account }}
            forWithdraw
          />
        )}
        {transfersEnabled && (
          <InternalTransfer textId="boInternalTransfers" slug="transfer" id="transfer" account={+account} />
        )}
      </PanelTabs>
    </PageLayout>
  );
};

CommonInternalTransferPage.propTypes = {
  addons: PropTypes.array.isRequired,
  transfersEnabled: PropTypes.bool.isRequired,
};

export default connect(state => ({
  addons: state.interfaceConfig.addons,
  transfersEnabled:
    state.interfaceConfig.internalTransfers.traders_enabled || state.interfaceConfig.internalTransfers.accounts_enabled,
}))(CommonInternalTransferPage);
