import css from 'styled-jsx/css';

import { gradient, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  @mixin afterLine {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    z-index: 2;
    transition: all 0.3s ease;
  }

  .noScroll {
    overflow: hidden !important;
  }

  .PageTabs {
    width: 100%;
    @include respond-to(sm) {
      overflow: scroll;
      a {
        white-space: nowrap;
      }
    }

    a {
      font-size: 20px;
      line-height: 24px;
      padding: 0 0 15px;
      text-align: center;
      position: relative;
      margin-right: ${isRTL ? '0' : '30px'};
      margin-left: ${isRTL ? '30px' : '0'};

      &.active {
        &:after {
          @include afterLine;
          left: 0;
        }
      }

      @include respond-to(sm) {
        font-size: 14px;
        line-height: 18px;
        padding: 0 5px 10px;
        margin-right: 25px;
      }

      &:last-child {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &.forVerification {
      a {
        padding-left: ${isRTL ? '0' : '35px'};
        padding-right: ${isRTL ? '35px' : '0'};
        margin-right: ${isRTL ? '0' : '30px'};
        margin-left: ${isRTL ? '30px' : '0'};
      }
    }
  }

  .forAuth {
    a {
      width: 50%;
      padding: 0 5px 20px;
      margin: 0;
      font-size: 17px;
      line-height: 19px;
      padding-right: ${isRTL ? '35px' : '0'};
      padding-left: ${isRTL ? '0' : '35px'};
      @include respond-to(xl) {
        font-size: 16px;
      }
      @include respond-to(xs) {
        font-size: 14px;
        line-height: 16px;
        padding: 0 3px 15px;
      }
      &:last-child {
        &.active {
          &:after {
            display: none;
          }
        }
      }
      &:first-child {
        &:after {
          @include afterLine;
          left: ${isRTL ? 'auto' : '100%'};
          right: ${isRTL ? '100%' : 'auto'};
        }
        &.active {
          &:after {
            left: ${isRTL ? 'auto' : '0'};
            right: ${isRTL ? '0' : 'auto'};
          }
        }
      }
    }
  }

  .forVerification {
    ol {
      list-style: none;
      display: flex;
    }
    .forVerification__tab-counter-inner {
      display: inline-block;
      position: relative;
      counter-increment: itemVerification;
      &.done {
        a {
          padding-left: 0;
          &:before {
            content: none;
          }
          margin-bottom: rem(10px);
        }
        &:last-child {
          a {
            margin: 0;
          }
        }
      }

      a {
        padding-right: ${isRTL ? '35px' : '0'};
        padding-left: ${isRTL ? '0' : '35px'};
        @include respond-to(sm) {
          padding-right: ${isRTL ? '25px' : '0'};
          padding-left: ${isRTL ? '0' : '25x'};
        }
        &:before {
          content: counter(itemVerification);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -1px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 500;
          line-height: 25px;

          &:before {
            content: counter(itemVerification);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -1px;
            left: 0;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;

            @include respond-to(sm) {
              font-size: 14px;
              width: 20px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .forPage {
      margin-bottom: 25px;
      padding-right: ${isRTL ? '35px' : '0'};
      padding-left: ${isRTL ? '0' : '35px'};
      ul {
        li {
          display: inline-block;
        }
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .PageTabs {
        a {
          transition: none;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PageTabs {
      a {
        color: ${colors.black.hex};

        &:after {
          background: ${gradient(73.29, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
        &.active {
          color: ${colors.primary.hex};
        }
      }
    }

    .forAuth {
      a {
        border-bottom: 1px solid ${colors.lightGray.hex};
      }
    }

    .forVerification {
      .forVerification__tab-inner {
        a {
          color: ${colors.lightGray.hex};

          &.active {
            color: ${colors.gray.hex};
          }

          &:before,
          &:after {
            background: ${colors.lightGray.hex};
          }
        }
      }

      .forVerification__tab-counter-inner {
        &.done {
          &:before,
          &:after {
            background: ${colors.white.hex};
          }

          &:before {
            left: ${isRTL ? '0' : '4px'};
            right: ${isRTL ? '12px' : '0'};
          }

          &:after {
            left: ${isRTL ? '0' : '9px'};
            right: ${isRTL ? '3px' : '0'};
          }

          a {
            color: ${colors.black.hex};
            &:after,
            &:before {
              background: ${colors.primary.hex};
            }
            &.active {
              color: ${colors.primary.hex};
            }
          }
        }
      }

      a {
        &:before {
          left: ${isRTL ? 'auto' : '0'};
          right: ${isRTL ? '0' : 'auto'};
          background-image: ${gradient(53, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
          color: ${colors.white.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
