import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withTheme, taggedStylesToString } from 'common/styling/theme';

import next from './next.svg';
import prev from './prev.svg';

import { staticStyles, getDynamicStyles } from './style';

const NewCRMPaginationNoQuery = ({ pageSize, setPageSize, currentPage, setCurrentPage, count, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const pagesCount = useMemo(() => Math.ceil(count / pageSize), [count, pageSize]);

  const handleCurrentPage = newParam => {
    if (typeof +newParam === 'number' && newParam >= 1 && newParam <= pagesCount) {
      setCurrentPage(newParam);
    } else {
      setCurrentPage(1);
    }
  };

  const handleChangePageSize = newParam => {
    setPageSize(newParam);
  };

  const handleCurrentPageInputChange = ({ target: { value } }) => {
    setCurrentPage(value);
  };

  return (
    <div className={classNames('NewCRMPaginationNoQuery')}>
      {pagesCount !== 0 && (
        <div className="NewCRMPaginationNoQuery__left">
          {/* prev button */}
          <button
            type="button"
            className="NewCRMPaginationNoQuery__left-prev"
            onClick={() => handleCurrentPage(Number(currentPage) - 1)}
            disabled={currentPage <= 1}>
            <img src={prev} alt="prev" />
          </button>

          {/* input page */}
          <div className="NewCRMPaginationNoQuery__left-pages">
            <input
              className="NewCRMPaginationNoQuery__left-pages-number"
              type="number"
              value={currentPage}
              onChange={e => handleCurrentPageInputChange(e)}
              onKeyUp={e => {
                if (e.key === 'Enter') handleCurrentPage(currentPage);
              }}
              onClick={e => e.target.select()}
            />
            <div className="NewCRMPaginationNoQuery__left-pages-count">{pagesCount}</div>
          </div>

          <button
            type="button"
            className="NewCRMPaginationNoQuery__left-next"
            onClick={() => handleCurrentPage(Number(currentPage) + 1)}
            disabled={currentPage >= pagesCount}>
            <img src={next} alt="next" />
          </button>
        </div>
      )}

      <div className="NewCRMPaginationNoQuery__right">
        {[10, 50, 100].map(size => (
          <button
            type="button"
            className={classNames('NewCRMPaginationNoQuery__right-count', {
              'NewCRMPaginationNoQuery__right-count--active': pageSize === size,
            })}
            onClick={() => handleChangePageSize(size)}
            key={`NewCRMPaginationNoQuery-size-${size}`}>
            {size}
          </button>
        ))}
      </div>
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

NewCRMPaginationNoQuery.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  theme: PropTypes.object,
};

NewCRMPaginationNoQuery.defaultProps = { theme: {} };

export default compose(withRouter, withTheme())(NewCRMPaginationNoQuery);
