import React from 'react';
import PropTypes from 'prop-types';

import { PaginationTable, DateCell } from 'common/components';

import { staticStyles } from './style';

const UserMarksTable = ({ data, onRowClick, loading, getList, location, history, params, theme, ...props }) => {
  /* eslint-disable */
  const columns = [
    {
      accessor: 'created',
      Header: 'justDate',
      Cell: ({
        row: {
          original: { created },
        },
      }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      accessor: 'utmCampaign',
      Header: 'justCampaign',
      Cell: ({
        row: {
          original: { utmCampaign },
        },
      }) => <span>{utmCampaign || '一'}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'utmMedium',
      Header: 'utmMedium',
      Cell: ({
        row: {
          original: { utmMedium },
        },
      }) => <span>{utmMedium || '一'}</span>,
      isPreviewCell: true,
    },
    {
      accessor: 'utmSource',
      Header: 'utmSource',
      Cell: ({
        row: {
          original: { utmSource },
        },
      }) => <span>{utmSource || '一'}</span>,
    },
    {
      accessor: 'utmContent',
      Header: 'justType',
      Cell: ({
        row: {
          original: { utmContent },
        },
      }) => <span>{utmContent || '一'}</span>,
    },
    {
      accessor: 'utmTerm',
      Header: 'utmTerm',
      Cell: ({
        row: {
          original: { utmTerm },
        },
      }) => <span>{utmTerm || '一'}</span>,
    },
    {
      accessor: 'ua',
      Header: 'utmDevice',
      Cell: ({
        row: {
          original: { ua },
        },
      }) => <span>{ua}</span>,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="UserMarksTable">
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

UserMarksTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

UserMarksTable.defaultProps = {
  theme: {},
};

export default UserMarksTable;
