import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AccountTable {
    .ReactTable {
      width: 100%;
      border: none;
      .rt-thead.-header {
        background-color: transparent;
        box-shadow: none;
        @include respond-to(lg) {
          min-width: 750px !important;
        }
        @include respond-to(sm) {
          min-width: 340px !important;
        }
        .rt-tr {
          box-shadow: none;
          border: none;
        }
        .rt-th {
          border: none;
          text-align: ${isRTL ? 'right' : 'left'};
          padding: 0;
          &:first-child {
            flex: 190 0 auto !important;
            width: 190px !important;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              flex: auto !important;
              width: 240px !important;
            }
            @include respond-to(lg) {
              flex: 140 0 auto !important;
              width: 140px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 160px !important;
              }
            }
            @include respond-to(sm) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 100px !important;
              }
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            flex: 90 0 auto !important;
            width: 90px !important;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              flex: auto !important;
              width: 120px !important;
            }
            @include respond-to(lg) {
              flex: 80 0 auto !important;
              width: 80px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 110px !important;
              }
            }
            @include respond-to(sm) {
              flex: 55 0 auto !important;
              width: 55px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 70px !important;
              }
            }
          }
          &:nth-child(4) {
            flex: 65 0 auto !important;
            width: 65px !important;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              flex: auto !important;
              width: 80px !important;
            }
            @include respond-to(lg) {
              flex: 55 0 auto !important;
              width: 55px !important;
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: auto !important;
                width: 70px !important;
              }
            }
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            @include respond-to(sm) {
              display: none;
            }
          }
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            flex: 120 0 auto !important;
            width: 120px !important;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              flex: auto !important;
              width: 135px !important;
            }
          }
          span {
            font-size: 11px;
            line-height: 18px;
            text-transform: uppercase;
          }
        }
      }

      .rt-tbody {
        @include respond-to(lg) {
          min-width: 750px !important;
        }
        @include respond-to(sm) {
          min-width: 340px !important;
        }
        .rt-tr-group {
          .rt-tr {
            margin: 0 0;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;
            border: none;
            padding: rem(10px 18px);
            @include respond-to(lg) {
              padding: rem(10px);
            }
            .rt-td {
              padding: 0;
              margin: 0 3px 0 0;
              &:last-child {
                margin: 0;
              }
              span {
                display: inline-block;
                font-size: rem(17px);
                transition: all 0.4s ease;
              }

              .paymentButtons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }

              &:nth-child(2),
              &:nth-child(3) {
                flex: 90 0 auto !important;
                width: 90px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 120px !important;
                }
                @include respond-to(lg) {
                  flex: 80 0 auto !important;
                  width: 80px !important;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto !important;
                    width: 110px !important;
                  }
                }
                @include respond-to(sm) {
                  flex: 55 0 auto !important;
                  width: 55px !important;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto !important;
                    width: 70px !important;
                  }
                }
              }
              &:nth-child(4) {
                flex: 65 0 auto !important;
                width: 65px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 80px !important;
                }
                @include respond-to(lg) {
                  flex: 55 0 auto !important;
                  width: 55px !important;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto !important;
                    width: 70px !important;
                  }
                }
              }

              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7) {
                display: flex;
                justify-content: flex-end;
                flex: 120 0 auto !important;
                width: 120px !important;
                margin: 0;
                text-overflow: unset;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 135px !important;
                }

                :global(.ActionsButton) {
                  margin-right: rem(30px);

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7) {
                @include respond-to(sm) {
                  display: none;
                }
              }

              &:first-child {
                flex: 190 0 auto !important;
                width: 190px !important;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                  flex: auto !important;
                  width: 240px !important;
                }
                @include respond-to(lg) {
                  flex: 140 0 auto !important;
                  width: 140px !important;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto !important;
                    width: 160px !important;
                  }
                }
                @include respond-to(sm) {
                  flex: 80 0 auto !important;
                  width: 80px !important;
                  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    flex: auto !important;
                    width: 100px !important;
                  }
                }
              }

              :global(.ActionsButton svg) {
                width: 25px;
                height: 25px;
              }

              :global(.Tooltip) {
                background: #ffffff !important;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
                border-radius: 5px;

                pre {
                  color: #000;
                }

                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .AccountTable {
      .ReactTable {
        .rt-thead.-header {
          .rt-th {
            span {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
