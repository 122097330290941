import css from 'styled-jsx/css';

import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/vars';
  @import 'src/common/styling/_mixins-scss/rem';

  .Calendar {
    .DateRangePicker_picker {
      left: -43px !important;
      @include respond-to(sm) {
        left: 0 !important;
      }
    }
  }

  .DateInput {
    width: 112px !important;
    background: transparent !important;
  }

  .DateRangePicker {
    z-index: 3;
  }

  .CalendarMonth_table {
    margin-top: 10px;
  }

  .DateInput_input {
    font-size: rem(15px) !important;
    color: #000000 !important;
    font-family: $ubuntu-font !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    background: transparent !important;
    padding: rem(8px 15px 6px) !important;
    border-bottom: 1px solid transparent !important;
  }

  .DateInput_input__focused {
    background: transparent !important;
  }

  .DateRangePickerInput__withBorder {
    background: transparent !important;
  }

  .DateRangePickerInput_arrow {
    padding: 0 5px;
  }

  .DateRangePickerInput_arrow_svg {
    width: 20px !important;
    height: 20px !important;
  }

  .CalendarMonth_caption {
    font-weight: 500 !important;
    text-transform: uppercase !important;
  }

  .DayPicker_weekHeader_li {
    small {
      text-transform: uppercase;
    }
  }

  .DayPickerNavigation_svg__horizontal {
    transition: all 0.4s ease;
  }

  .CalendarDay__default {
    border: 1px solid transparent !important;
    font-weight: 500 !important;
    font-family: $ubuntu-font !important;
    transition: all 0.4s ease !important;
  }

  .CalendarDay__blocked_out_of_range {
    cursor: not-allowed;
  }

  .DayPickerNavigation_button__horizontal {
    z-index: 3;
    &:active {
      background-color: transparent;
    }
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 10px;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 10px;
  }

  .Calendar {
    .DayPicker_transitionContainer__verticalScrollable {
      position: static;
    }

    .DayPickerNavigation_button__vertical {
      border: none;
      width: 100%;
    }

    .DayPicker__withBorder {
      @include respond-to(sm) {
        box-shadow: none;
      }
    }
  }

  .Calendar__data-info {
    font-size: rem(16px);
    margin-bottom: rem(10px);
    padding: rem(10px);
    position: relative;
    bottom: 3px;
    @include respond-to(sm) {
      font-size: rem(15px);
      margin-bottom: rem(20px);
    }
  }

  .Calendar__datePicker-inner {
    position: absolute;
    right: 0;
    z-index: 30;

    &--isRightPosition {
      right: initial;
    }
    &--isMiddlePosition {
      right: initial;
      left: -200px;
    }
    @include respond-to(sm) {
      /* width: calc(100% + 20px); */
      box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
      width: max-content;
      height: 465px;
      right: -10px;
      left: -10px;
      background: #fff;
      overflow: auto;
    }
    .DayPickerNavigation_nextButton__verticalDefault {
      @include respond-to(sm) {
        position: absolute;
        background: #fff;
        height: auto;
        bottom: -55px;
        left: 0;
        width: 100%;
        z-index: 100;
      }
    }
    .CalendarMonth_caption {
      @include respond-to(sm) {
        padding: 10px 0 0 0;
      }
    }
    .DayPicker_transitionContainer {
      @include respond-to(sm) {
        height: 360px !important;
        top: 40px !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .DateInput_input__focused {
      border-bottom: 1px solid ${colors.primary.hex} !important;
    }

    .DateRangePickerInput__withBorder {
      border: 1px solid ${colors.lightGray.hex} !important;
      border-radius: ${border.radius} !important;
      &:focus {
        border-color: ${colors.primary.hex} !important;
      }
    }

    .DayPickerNavigation_svg__vertical {
      fill: ${colors.primary.hex} !important;
    }

    .CalendarMonth_caption {
      color: ${colors.primary.hex} !important;
    }

    .DayPicker_weekHeader_li {
      small {
        color: ${colors.primary.hex} !important;
      }
    }

    .DayPickerNavigation_button__horizontal {
      border-color: ${rgba([colors.primary.rgb, 0.4])} !important;
    }

    .DayPickerNavigation_button__default:focus,
    .DayPickerNavigation_button__default:hover {
      border-color: ${colors.primary.hex} !important;
      .DayPickerNavigation_svg__horizontal {
        fill: ${colors.primary.hex} !important;
      }
    }

    .DayPickerNavigation_svg__horizontal {
      fill: ${rgba([colors.primary.rgb, 0.4])} !important;
    }

    .CalendarDay__default {
      color: ${colors.gray.hex} !important;
    }

    .CalendarDay__default:hover {
      background: ${rgba([colors.gradientEnd.rgb, 0.25])} !important;
    }

    .CalendarDay__selected {
      background: ${rgba([colors.gradientEnd.rgb, 0.45])} !important;
    }

    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${rgba([colors.gradientEnd.rgb, 0.6])} !important;
    }

    .CalendarDay__selected_span {
      background: ${rgba([colors.gradientEnd.rgb, 0.25])} !important;
      &:hover {
        background: ${rgba([colors.gradientEnd.rgb, 0.45])} !important;
      }
    }

    .CalendarDay__blocked_out_of_range {
      background: ${rgba([colors.lightGray.rgb, 0.4])} !important;
      &:hover {
        background: ${rgba([colors.lightGray.rgb, 0.4])} !important;
      }
    }

    .Calendar__data-info {
      border-radius: ${border.radius};
      border: 1px solid ${colors.primary.hex};
      color: ${colors.primary.hex};
      &:hover {
        border-color: ${rgba([colors.primary.rgb, 0.8])};
        background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
        color: ${rgba([colors.primary.rgb, 0.8])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
