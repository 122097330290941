import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TopMenu {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: 0 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    position: fixed;
    width: 100%;
    z-index: 20;
    top: 0;
    @include respond-to(md) {
      padding: 0 15px 0 5px;
      height: 65px;
    }
    @include respond-to(sm) {
      padding: 0 5px 0 5px;

      :global(.TasksInfo__inner) {
        display: none;
      }
    }

    .MenuIcon {
      height: 75px;
      @include respond-to(md) {
        height: 65px;
      }
      .MenuIcon__inner {
        svg {
          fill: none;
        }
      }
    }

    .MainLogo {
      @include respond-to(xs) {
        margin-right: 0;
      }
    }
  }

  .TopMenu-crm {
    @include respond-to(xs) {
      padding: 0 0 0 0;
    }

    .ClientInfo__status-name {
      color: white;
    }
    /* @include respond-to(lg) {
      .ClientInfo__status-name {
        display: none;
      }
      .LangButton {
        width: 191px;
      }
    } */
  }

  /* .ClientInfo__status-name {
    @include respond-to(sm) {
      display: none;
    }
  } */

  .TopMenu__left-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;

    .hamburger {
      @include respond-to(sm) {
      }
    }
  }

  .TopMenu__logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 2px;
  }

  .TopMenu__right-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @include respond-to(md) {
      height: 65px !important;
    }
  }

  .Notifications {
    @include respond-to(sm) {
      padding: 0 5px 0 0 !important;
    }
  }

  .GetClientButton {
    @include respond-to(md) {
      display: none !important;
    }
  }

  .SearchBar {
    @include respond-to(lg) {
      display: none !important;
    }
  }

  // client style
  .TopMenu-client {
    .TopMenu__switch-interface-inner {
      .Select-option {
        &:nth-child(2) {
          border: none !important;
        }
      }
    }
  }

  // admin style
  .TopMenu-admin {
    .ClientInfo__langButton {
      .Select__menu {
        top: 0 !important;
      }
    }
  }

  // backoffice style
  .TopMenu-backoffice {
    .ClientInfo__langButton {
      .Select__menu {
        top: 0 !important;
      }
    }
  }
  // crm style
  .TopMenu-crm {
    height: 96px;

    @include respond-to(sm) {
      height: 65px;
    }

    .TopMenu__left-inner {
      .hamburger {
        margin-right: 0;
        border-right: none !important;

        &-inner {
          width: 50%;
        }
      }

      .TopMenu__switch-interface-inner {
        width: 162px !important;
        padding: 8px;
        padding-right: 12px;
        border-radius: 8px;

        .Select__single-value {
          letter-spacing: normal !important;
          text-transform: none !important;
        }
      }
    }

    .TopMenu__right-inner {
      height: 75px;

      .TopMenu__clientInfoCrm {
        .ClientInfo__inner {
          border-left: none;
          padding-left: 0;
        }

        .ClientInfo__status-name {
          strong::after {
            display: none;
          }
        }

        @include respond-to(sm) {
          .ClientInfo__image {
            &::after {
              display: none;
            }
          }
        }

        @include respond-to(xs) {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .ClientInfo__inner {
            padding: 0;
          }

          .ClientInfo__image {
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .TopMenu__balance,
  .TopMenu__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(0 15px);

    @media only screen and (max-width: 530px) {
      display: none;
    }
  }

  .TopMenu__balance-caption,
  .TopMenu__status-caption {
    text-transform: uppercase;
    font-size: rem(11px);
    line-height: 1.5;
  }

  .TopMenu__balance-amount {
    font-size: rem(15px);
  }

  .TopMenu__status {
    height: 100%;
    margin-bottom: 1px;
  }
  .TopMenu__changeLangBarCrm {
    display: flex;

    @media only screen and (max-width: 1260px) {
      display: none;
    }
  }
  .TopMenu__clientInfoCrm {
    .ClientInfo__inner:hover .ClientInfo__status-name strong {
      color: #e3e3e3;
    }
    .LangButton .Select__single-value {
      color: black;
    }
  }
  .TopMenu__changeStatusCrm {
    display: flex;
    .TopMenu__status-caption {
      color: #fff;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .TopMenu__changeStatusCrm {
      .TopMenu__status {
        border-left: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      }
    }
    .TopMenu {
      background-color: ${colors.topMenu.hex};
      .TopMenu__logo {
        svg {
          .white {
            fill: ${colors.black.hex} !important;
          }
        }
      }
      .MenuIcon {
        .MenuIcon__inner {
          svg {
            .Gradient {
              stroke: ${colors.primary.hex};
            }
          }
        }
      }
    }

    // admin style
    .TopMenu-admin {
      background-color: ${colors.topMenu.hex};
      .TopMenu__logo {
        svg {
          .white {
            fill: ${colors.white.hex} !important;
          }
        }
      }
      .ClientInfo__inner {
        border-left: 1px solid ${rgba([colors.white.rgb, 0.2])};
        @include respond-to(sm) {
          border: none;
        }
        &:hover {
          .ClientInfo__status-name {
            strong {
              color: ${colors.primary.hex};
              &:after {
                border-color: ${colors.white.hex};
              }
            }
          }
        }
        .ClientInfo__status-name {
          color: ${colors.white.hex};
          strong {
            &:after {
              border-color: ${colors.primary.hex};
            }
          }
          span {
            color: ${colors.primary.hex};
          }
        }
        .ClientInfo__image {
          @include respond-to(sm) {
            border: 2px solid ${colors.white.hex};
            &:after {
              border-color: ${colors.primary.hex};
            }
          }
        }
      }

      .MenuIcon {
        border-left: 1px solid ${rgba([colors.white.rgb, 0.2])};
        .MenuIcon__inner {
          svg {
            .white {
              fill: ${colors.white.hex} !important;
            }
            .Gradient {
              stroke: ${colors.white.hex};
            }
            .StrokeWhite {
              stroke: ${colors.white.hex} !important;
            }
          }
        }
      }

      .hamburger {
        border-right: 1px solid ${rgba([colors.white.rgb, 0.2])};
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background: ${colors.white.hex};
        }
      }
    }

    .TopMenu__balance,
    .TopMenu__status {
      border-left: 1px solid ${rgba([colors.gray.rgb, 0.2])};

      @include respond-to(sm) {
        border-right: 1px solid ${rgba([colors.gray.rgb, 0.2])};
      }

      &:hover {
        background: ${gradient(0, [colors.gradientStart.rgb, 0.3], 0, [colors.gradientEnd.rgb, 0.1], 100)};
      }
    }

    .TopMenu__balance-caption,
    .TopMenu__status-caption {
      color: ${rgba([colors.gray.rgb, 0.7])};
    }

    .TopMenu__balance-amount {
      color: ${colors.primary.hex};
    }

    // backoffice style
    .TopMenu-backoffice {
      background: ${gradient(71.84, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      .TopMenu__logo {
        svg {
          .white {
            fill: ${colors.white.hex} !important;
          }
          .black {
            fill: ${colors.black.hex};
          }
        }
      }

      .TopMenu__switch-interface-inner {
        .Select__dropdown-indicator {
          border-color: ${colors.white.hex} !important;
        }
        .Select__single-value {
          color: ${colors.white.hex} !important;
        }
      }

      .MenuIcon {
        border-left: 1px solid ${rgba([colors.white.rgb, 0.4])};
        &.active {
          background-image: ${gradient(0, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0], 100)};
          &:after {
            background: ${colors.white.hex};
          }
        }
        .MenuIcon__inner {
          svg {
            .white {
              fill: ${colors.white.hex} !important;
            }
            .Gradient {
              stroke: ${colors.white.hex};
            }
            .StrokeWhite {
              stroke: ${colors.white.hex} !important;
            }
          }
        }
      }

      .hamburger {
        border-right: 1px solid ${rgba([colors.white.rgb, 0.4])};
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background: ${colors.white.hex};
        }
      }

      .ClientInfo__inner {
        border-left: 1px solid ${rgba([colors.white.rgb, 0.4])};
        @include respond-to(sm) {
          border: none;
        }
        &:hover {
          .ClientInfo__status-name {
            strong {
              color: ${colors.secondary.hex};
              &:after {
                border-color: ${colors.secondary.hex};
              }
            }
          }
        }
        .ClientInfo__status-name {
          span {
            color: ${colors.white.hex};
          }
        }
        .ClientInfo__image {
          border: 2px solid ${colors.white.hex};
        }
      }
    }

    // crm style
    .TopMenu-crm {
      background: ${colors.white.hex};

      .TopMenu__left-inner {
        .hamburger {
          &-inner,
          &-inner::after,
          &-inner::before {
            background: ${colors.black.hex} !important;
          }
        }

        .TopMenu__switch-interface-inner {
          background: ${rgba([colors.primary.rgb, 0.1])};

          .Select__single-value {
            color: ${colors.black.hex} !important;
          }

          .Select__dropdown-indicator {
            border-color: ${colors.black.hex} !important;
          }
        }
      }

      .TopMenu__clientInfoCrm {
        .ClientInfo__status-name {
          color: ${colors.black.hex} !important;
        }

        .ClientInfo__inner {
          &:hover {
            .ClientInfo__status-name {
              strong {
                color: ${colors.black.hex};
              }
            }
          }
        }
      }

      @include respond-to(lg) {
        .ClientInfo__image {
          position: relative;
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
