import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .PartnershipUsersTable {
    min-width: 300px;
    @include respond-to(sm) {
      overflow-x: scroll;
    }

    .PartnershipUsersTable__user {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        margin-right: 10px;
      }

      span {
        max-width: 100px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .PartnershipUsersTable {
      :global(.ReactDataTable) {
        tr {
          .ReactDataTable__tbody-cell {
            .time {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
