import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import { useParams } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { PageContentPreloader } from 'common/components';

import { getUserTotalPayments } from 'backoffice/users/_redux';

import { staticStyles, getDynamicStyles } from './style';

const UserTotalPayments = ({ getUserTotalPayments, totalPayments, loadingTotalPayments, theme }) => {
  const { id } = useParams();

  useEffect(() => {
    getUserTotalPayments(id);
  }, [getUserTotalPayments]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const rows = useMemo(
    () =>
      Object.keys(totalPayments).length
        ? [
            {
              rowTextId: 'boLifeTime',
              deposits: totalPayments.deposits.total,
              withdrawals: totalPayments.withdrawals.total,
            },
            {
              rowTextId: 'justLastYear',
              deposits: totalPayments.deposits.lastYear,
              withdrawals: totalPayments.withdrawals.lastYear,
            },
            {
              rowTextId: 'justLastSixMonths',
              deposits: totalPayments.deposits.lastSixMonths,
              withdrawals: totalPayments.withdrawals.lastSixMonths,
            },
          ]
        : [],
    [totalPayments]
  );

  return (
    <PageContentPreloader ready={loadingTotalPayments} type="smallLines" firstLaunchOnly>
      <div className="UserTotalPayments__Table">
        <div className="UserTotalPayments__Table__row UserTotalPayments__Table__row--header">
          <div className="UserTotalPayments__Table__col">
            <FM id="justTime" />
          </div>
          <div className="UserTotalPayments__Table__col">
            <FM id="justDepositsTotal" />
          </div>
          <div className="UserTotalPayments__Table__col">
            <FM id="justWithdrawalsTotal" />
          </div>
        </div>
        {rows.map((row, index) => (
          <div className="UserTotalPayments__Table__row" key={index}>
            <div className="UserTotalPayments__Table__col">
              <FM id={row.rowTextId} />
            </div>
            <div className="UserTotalPayments__Table__col">
              {row.deposits ? `${row.deposits.toFixed(2)} USD` : '---'}
            </div>
            <div className="UserTotalPayments__Table__col">
              {row.withdrawals ? `${row.withdrawals.toFixed(2)} USD` : '---'}
            </div>
          </div>
        ))}
        <style jsx>{staticStyles}</style>
        <style jsx>{dynamicStyles}</style>
      </div>
    </PageContentPreloader>
  );
};

UserTotalPayments.propTypes = {
  getUserTotalPayments: PropTypes.func.isRequired,
  totalPayments: PropTypes.object,
  theme: PropTypes.object,
  loadingTotalPayments: PropTypes.bool,
};

UserTotalPayments.defaultProps = {
  totalPayments: {},
  theme: {},
  loadingTotalPayments: true,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      totalPayments: state.backoffice.users.totalPayments,
      loadingTotalPayments: state.backoffice.users.loadingTotalPayments,
    }),
    {
      getUserTotalPayments: id => getUserTotalPayments.request(id),
    }
  )
)(UserTotalPayments);
