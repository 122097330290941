import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ActionsButton {
    width: max-content;
    position: relative;
    margin-left: rem(${isRTL ? '0' : '7px'});
    margin-right: rem(${isRTL ? '7px' : '0'});
    top: rem(2px);
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
    svg {
      width: rem(17px);
      height: rem(17px);
      path {
        transition: all 0.2s ease;
        fill: #777;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ActionsButton__edit {
      &:hover {
        svg {
          path {
            fill: ${colors.warning.hex};
          }
        }
      }
      &:disabled {
        &:hover {
          :global(svg) {
            :global(path) {
              fill: ${colors.gray.hex};
            }
          }
        }
      }
      :global(svg) {
        &:hover {
          :global(path) {
            fill: ${colors.warning.hex};
          }
        }
      }
    }

    .ActionsButton__delete {
      &:hover {
        svg {
          path {
            fill: ${colors.error.hex};
          }
        }
      }
      &:disabled {
        &:hover {
          :global(svg) {
            :global(path) {
              fill: ${colors.gray.hex};
            }
          }
        }
      }
      :global(svg) {
        &:hover {
          :global(path) {
            fill: ${colors.error.hex};
          }
        }
      }
    }

    .ActionsButton__watch {
      &:hover {
        svg:not(.notFillIcon) {
          path {
            fill: ${colors.primary.hex};
          }

          rect {
            stroke: ${colors.primary.hex};
          }
        }
      }
      &:disabled {
        &:hover {
          :global(svg) {
            :global(path) {
              fill: ${colors.gray.hex};
            }
          }
        }
      }
      :global(svg:not(.notFillIcon)) {
        &:hover {
          :global(path) {
            fill: ${colors.primary.hex};
          }
          :global(rect) {
            stroke: ${colors.primary.hex};
          }
        }
      }
      :global(.notFillIcon) {
        &:hover {
          g,
          path {
            stroke: ${colors.primary.hex};
            opacity: 1;
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
