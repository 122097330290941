import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { StatusCell, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';

import FlagImage from '../FlagImage';
import UserNoInfoPanel from '../UserNoInfoPanel';

import { staticStyles, getDynamicStyles } from './style';

const OperationsHistory = ({ items, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="OperationsHistory">
      <FormattedMessage id="boPaymentHistory">{txt => <h2>{txt}</h2>}</FormattedMessage>

      {items && items.length ? (
        <Scrollbars className="BasicModal__scroll" autoHeight autoHeightMax={500}>
          {items.map(item => (
            <div className="OperationsHistory__infoWrapper" key={item.at}>
              <div className="OperationsHistory__info">
                <span className="OperationsHistory__info-status">
                  <StatusCell statusCode={item.change} noDot />
                </span>
                <DateCell value={item.at} />
                {item.performedBy && (
                  <span>
                    <FormattedMessage id="justBy" />{' '}
                    {item.performedBy.email ? item.performedBy.email : item.performedBy}
                  </span>
                )}
              </div>
              {item.ip && (
                <div className="OperationsHistory__infoIp">
                  <span className="OperationsHistory__infoIp-ip">IP: {item.ip}</span>
                  <span className="OperationsHistory__infoIp-country">
                    <FlagImage flag={item.ipCountry.country_code} country={item.ipCountry.country_name} />
                  </span>
                </div>
              )}
            </div>
          ))}
        </Scrollbars>
      ) : (
        <UserNoInfoPanel image={noInfoImage} textId="boNoHistory" />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

OperationsHistory.propTypes = {
  items: PropTypes.array,
  theme: PropTypes.object,
};

OperationsHistory.defaultProps = {
  items: [],
  theme: {},
};

export default withTheme()(OperationsHistory);
export { OperationsHistory };
