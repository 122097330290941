import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required } from 'redux-form-validators';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { isValidNumber } from 'libphonenumber-js';
import { compose } from 'redux';

import { FormMessageHOC } from 'common';
import {
  ReduxSelectField,
  ReduxTextField,
  PhoneField,
  CheckboxField,
  DateField,
  DefaultButton,
} from 'common/components';
import { withTheme } from 'common/styling/theme';

import { editUserKYC } from '../../_redux';
import { ExperienceFields, SelectCountryField } from './fields';

import { staticStyles, getDynamicStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) {
    return <FormattedMessage id="validationErrorWrongPhone" />;
  }
};

const UserKYCForm = ({
  user,
  handleSubmit,
  submitting,
  error,
  countries,
  states,
  countriesAreLoaded,
  statesAreLoaded,
  experienceForex,
  experienceDerivative,
  experienceSecurities,
  experienceInvestmentFund,
  mandatoryDocumentsAreSending,
  removeKycTaxCheckbox,
  dispatch,
  invalid,
  intl,
  theme,
}) => {
  const submit = handleSubmit(formValues => editUserKYC({ id: user.id, ...formValues }, dispatch));

  const countriesOptions = countries.map(option => ({
    value: option.gid,
    label: option.name,
  }));

  const generateOptionsFromTranslations = keys =>
    keys.map(key => ({ value: key, label: intl.formatMessage({ id: key }) }));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const disabled = submitting;

  return (
    <form className="UserKYCForm" onSubmit={submit}>
      <div className="UserKYCForm__inputs-inner">
        <FormattedMessage id="generalInfo">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="UserKYCForm__inputs-block">
          <Field component={ReduxTextField} type="text" name="firstName" textId="kycFirstName" disabled={disabled} />
          <Field component={ReduxTextField} type="text" name="middleName" textId="kycMiddleName" disabled={disabled} />
          <Field component={ReduxTextField} type="text" name="lastName" textId="kycLastName" disabled={disabled} />
        </div>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="taxResidence"
            textId="kycTaxResidence"
            options={countriesOptions}
            disabled={disabled}
            searchable
          />
          <Field
            component={DateField}
            type="date"
            name="birthday"
            textId="kycBirthday"
            placeholder="dd.mm.yyyy"
            disable={disabled}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="nationality"
            textId="kycNationality"
            options={countriesOptions}
            disabled={disabled}
            searchable
          />
          <Field
            component={PhoneField}
            name="phone"
            textId="kycPhone"
            disabled={disabled}
            validate={[required(), validatePhoneNumber]}
          />
        </div>

        {!removeKycTaxCheckbox && (
          <Field component={CheckboxField} name="usTax" textId="kycUsTax" disabled={disabled} validate={[required()]} />
        )}
      </div>
      <div className="UserKYCForm__inputs-inner">
        <FormattedMessage id="kycAddress">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="UserKYCForm__inputs-block">
          <Field
            component={SelectCountryField}
            name="address.country"
            textId="kycCountry"
            options={countriesOptions}
            disabled={disabled || !countriesAreLoaded}
          />
          <Field
            component={ReduxSelectField}
            name="address.state"
            textId="kycState"
            options={states.map(option => ({
              value: option.gid,
              label: option.name,
            }))}
            disabled={disabled || !statesAreLoaded}
            searchable
          />
          <Field
            component={ReduxTextField}
            type="text"
            name="address.postCode"
            textId="kycPostCode"
            disabled={disabled}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field component={ReduxTextField} type="text" name="address.city" textId="kycCity" disabled={disabled} />
          <Field
            component={ReduxTextField}
            type="text"
            name="address.address"
            textId="kycAddress"
            disabled={disabled}
          />
        </div>
      </div>
      <div className="UserKYCForm__inputs-inner">
        <FormattedMessage id="kycFinDetails">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="UserKYCForm__inputs-block">
          <Field
            component={ReduxSelectField}
            name="finance.capital"
            textId="kycNetCapital"
            options={generateOptionsFromTranslations([
              'kycAmountBelow10',
              'kycAmount10to50',
              'kycAmount50to100',
              'kycAmountOver100',
            ])}
            disabled={disabled}
          />
          <Field
            component={ReduxSelectField}
            name="finance.income"
            textId="kycAnnualIncome"
            options={generateOptionsFromTranslations([
              'kycAmountBelow10',
              'kycAmount10to50',
              'kycAmount50to100',
              'kycAmountOver100',
            ])}
            disabled={disabled}
          />
          <Field
            component={ReduxSelectField}
            name="finance.commitments"
            textId="kycMonFinCommit"
            options={generateOptionsFromTranslations([
              'kycCommitmentsBelow20',
              'kycCommitments21to40',
              'kycCommitments41to60',
              'kycCommitments61to80',
              'kycCommitmentsOver80',
            ])}
            disabled={disabled}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxTextField}
            type="text"
            name="finance.investments"
            textId="kycApproxInvestPerAnn"
            disabled={disabled}
          />
          <Field
            component={ReduxSelectField}
            name="finance.purpose"
            textId="kycPurpose"
            options={generateOptionsFromTranslations([
              'kycPurposeInvestment',
              'kycPurposeHedging',
              'kycPurposeSpecTrading',
            ])}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="UserKYCForm__inputs-inner">
        <FormattedMessage id="kycTypeOfActivity">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="activity.employment"
            textId="kycEmplStatus"
            options={generateOptionsFromTranslations([
              'kycEmploymentEmpl',
              'kycEmploymentUnempl',
              'kycEmploymentSelfEmpl',
              'kycEmploymentRet',
              'kycEmploymentStud',
            ])}
            disabled={disabled}
          />
          <Field
            component={ReduxSelectField}
            name="activity.source"
            textId="kycSourceOfFunds"
            options={generateOptionsFromTranslations([
              'kycSourceProfAct',
              'kycSourceSavings',
              'kycSourceFam',
              'kycSourceInvest',
              'kycSourceBorrow',
              'kycSourcePension',
              'kycSourceOther',
            ])}
            disabled={disabled}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-two-inp">
          <Field
            component={ReduxSelectField}
            name="activity.business"
            textId="kycNatureOfBusiness"
            options={generateOptionsFromTranslations([
              'kycBizAccount',
              'kycBizSecr',
              'kycBizAgric',
              'kycBizArt',
              'kycBizBank',
              'kycBizCatHosp',
              'kycBizMedia',
              'kycBizEdu',
              'kycBizEmerg',
              'kycBizEngin',
              'kycBizHealth',
              'kycBizIT',
              'kycBizLegal',
              'kycBizLeisure',
              'kycBizManuf',
              'kycBizPR',
              'kycBizProp',
              'kycBizSales',
              'kycBizSocial',
              'kycBizTelecom',
              'kycBizTransport',
              'kycBizOther',
            ])}
            disabled={disabled}
            searchable
          />
          <Field
            component={ReduxSelectField}
            name="activity.education"
            textId="kycLvlOfEdu"
            options={generateOptionsFromTranslations(['kycEduHS', 'kycEduColl', 'kycEduUnivers', 'kycEduOther'])}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="UserKYCForm__inputs-inner">
        <FormattedMessage id="kycTradingExp">{txt => <h2>{txt}</h2>}</FormattedMessage>

        <div className="UserKYCForm__inputs-block block-experience">
          <ExperienceFields
            prefix="experience.forex"
            textId="kycForexCdfs"
            disabled={disabled}
            active={experienceForex}
            generateOptionsFromTranslations={generateOptionsFromTranslations}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-experience">
          <ExperienceFields
            prefix="experience.derivative"
            textId="kycDerivativeInstr"
            disabled={disabled}
            active={experienceDerivative}
            generateOptionsFromTranslations={generateOptionsFromTranslations}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-experience">
          <ExperienceFields
            prefix="experience.securities"
            textId="kycTransfSec"
            disabled={disabled}
            active={experienceSecurities}
            generateOptionsFromTranslations={generateOptionsFromTranslations}
          />
        </div>

        <div className="UserKYCForm__inputs-block block-experience">
          <ExperienceFields
            prefix="experience.investmentFund"
            textId="kycUnitsCollectiveInv"
            disabled={disabled}
            active={experienceInvestmentFund}
            generateOptionsFromTranslations={generateOptionsFromTranslations}
          />
        </div>
      </div>

      {!mandatoryDocumentsAreSending && (
        <div className="UserKYCForm__inputs-inner KYCForm__text">
          <FormattedMessage id="kycUploadeMandatoryDocuments" />
        </div>
      )}
      <DefaultButton textId="sendToVerify" type="submit" disabled={submitting || invalid} loading={submitting} filled />
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </form>
  );
};

UserKYCForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  experienceForex: PropTypes.bool.isRequired,
  experienceDerivative: PropTypes.bool.isRequired,
  experienceSecurities: PropTypes.bool.isRequired,
  experienceInvestmentFund: PropTypes.bool.isRequired,
  error: PropTypes.string,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  countriesAreLoaded: PropTypes.bool.isRequired,
  statesAreLoaded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  mandatoryDocumentsAreSending: PropTypes.bool.isRequired,
  removeKycTaxCheckbox: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

UserKYCForm.defaultProps = {
  error: '',
  theme: {},
};

const selector = formValueSelector('UserKYCForm');

export default compose(
  withTheme(),
  FormMessageHOC,
  injectIntl,
  connect(state => ({
    experienceForex: selector(state, 'experience.forex.checkbox') || false,
    experienceDerivative: selector(state, 'experience.derivative.checkbox') || false,
    experienceSecurities: selector(state, 'experience.securities.checkbox') || false,
    experienceInvestmentFund: selector(state, 'experience.investmentFund.checkbox') || false,
    removeKycTaxCheckbox: state.interfaceConfig.features.includes('removeKycTaxCheckbox'),
  })),
  reduxForm({
    form: 'UserKYCForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage();
    },
  })
)(UserKYCForm);
export { UserKYCForm };
