import { combineReducers } from 'redux';

import { LOGOUT_SUCCESS } from 'auth';

import { reducer as common } from './commonReducer';
import { reducer as users } from '../users';
import { reducer as payments } from '../payments';
import { reducer as support } from '../support';
import { reducer as news } from '../news';
import { reducer as partnership } from '../partnership';
import { reducer as transfers } from '../transfers';
import { reducer as bonuses } from '../bonuses';
import { reducer as faq } from '../faq';
import { reducer as promo } from '../promo';
import { reducer as managers } from '../managers';
import { reducer as templates } from '../templates';
import { reducer as statuses } from '../statuses';
import { reducer as invest } from '../invest';

const reducers = combineReducers({
  common,
  users,
  payments,
  support,
  news,
  partnership,
  transfers,
  bonuses,
  faq,
  promo,
  managers,
  templates,
  statuses,
  invest,
});

const reducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return reducers(state, action);
};

export default reducer;
