import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import classNames from 'classnames';

import {
  getInvestmentsNew,
  getInvestmentsRequestsNew,
  setInvestmentsActionNew,
  closeInvestmentRequestNew,
} from 'client/investNew/_redux';

import { ModalHOC } from 'common';
import { Collapsible, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';
import InvestmentsTable from '../InvestmentsTable';
import InvestmentDepositFormNew from '../InvestmentDepositFormNew';

import InvestCloseFormNew from '../InvestCloseFormNew';
import InvestWithdrawFormNew from '../InvestWithdrawFormNew';

import { staticStyles, getDynamicStyles } from './style';

const InvestInvesmentsNew = ({
  accounts,
  addons,
  investments,
  investmentsAreLoaded,
  investmentsRequests,
  investmentsRequestsAreLoaded,
  getInvestmentsNew,
  getInvestmentsRequestsNew,
  closeInvestmentRequestNew,
  showModal,
  profile,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const dispatch = useDispatch();
  const location = useLocation();
  const query = useMemo(() => {
    const obj = { page: 1, page_size: 10, ...queryString.parse(location.search) };
    return { ...obj, page: obj.page === '0' ? undefined : obj.page };
  }, [location.search]);

  console.log({ query });
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('active');
  const [isOpen, setIsOpen] = useState(false);
  const [activeModal, setActiveModal] = useState({ type: 'deposit' });
  const isWallet = addons.includes('walletAccount');
  const prevTabRef = useRef('');

  useEffect(() => {
    prevTabRef.current = activeTab;
  }, [activeTab]);

  const getList = useCallback(
    (query, tab) =>
      getInvestmentsNew({
        is_active: tab === 'active' ? 'True' : 'False',
        ...query,
      }),
    [getInvestmentsNew]
  );

  const getInvestmentsList = useCallback(
    query =>
      getInvestmentsNew({
        is_active: activeTab === 'active' ? 'True' : 'false',
        ...query,
      }),
    [getInvestmentsNew, activeTab]
  );

  const getRequestsList = useCallback(query => getInvestmentsRequestsNew(query), [getInvestmentsRequestsNew, query]);

  const toggleModal = () => setIsOpen(!isOpen);

  const handleOpenCollapse = () => getList(query, activeTab);

  const handleInvestmentsRowClick = id => history.push(`/investNew/investments/${id}/open`);

  const handleRequestsRowClick = () => {};

  const handleDeposit = (id, strategy) => {
    setActiveModal({
      id,
      title: strategy.title,
      strategyAccountId: strategy.accountId,
      strategyCurrency: strategy.currency,
      strategyId: strategy.id,
      type: 'deposit',
    });
    toggleModal();
  };

  const handleClose = (id, strategyId) => {
    setActiveModal({ id, type: 'close', strategyId });

    toggleModal();
  };

  const handleWithdraw = (id, status, isMyStrategy, strategyId) => {
    setActiveModal({
      id,
      title: 'justWithdraw',
      type: 'withdraw',
      strategyId,
      status,
      isMyStrategy,
    });
    toggleModal();
  };

  const handleCancelRequest = id => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'investConfirmRequestCancel',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => closeInvestmentRequestNew({ requestId: id, search: query }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const handleAction = ({ e, id, type, status, strategy }) => {
    e.stopPropagation();

    switch (type) {
      case 'withdraw':
        handleWithdraw(id, status, strategy.isMyStrategy, strategy.id);
        break;
      case 'close':
        handleClose(id, strategy.id);
        break;
      case 'deposit':
        handleDeposit(id, strategy);
        break;
      // case 'edit':
      //   handleEditTpSl(id, strategyCurrency);
      //   break;
      case 'cancel_request':
        handleCancelRequest(id);
        break;
      default:
        break;
    }
  };

  const modalTitle = str => {
    const firstLetterToUpperCase = str.charAt(0).toUpperCase() + str.substring(1);

    switch (activeModal.type) {
      case 'deposit':
        return 'justDeposit';
      case 'edit':
        return 'justEdit';
      default:
        return `rammChange${firstLetterToUpperCase}`;
    }
  };

  return (
    <div className="InvestInvestments">
      <Collapsible captionId="justInvestments" handleOpen={handleOpenCollapse}>
        <div className="InvestInvestments__tabs">
          <FormattedMessage id="justActive">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'active',
                })}
                onClick={() => {
                  setActiveTab('active');
                  getList(query, 'active');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justClosed">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'closed',
                })}
                onClick={() => {
                  setActiveTab('closed');
                  getList(query, 'closed');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justRequests">
            {txt => (
              <button
                type="button"
                className={classNames('InvestInvestments__tab', {
                  active: activeTab === 'requests',
                })}
                onClick={() => {
                  setActiveTab('requests');
                  getRequestsList({ search: query });
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        {activeTab === 'active' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investments}
            loading={!investmentsAreLoaded}
            location={location}
            history={history}
            getList={getInvestmentsList}
            onRowClick={handleInvestmentsRowClick}
            params={{ search: query }}
            onTableAction={handleAction}
            isNewInevest
          />
        )}

        {activeTab === 'closed' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investments}
            loading={!investmentsAreLoaded}
            location={location}
            history={history}
            getList={getInvestmentsList}
            onRowClick={handleInvestmentsRowClick}
            isNewInevest
            params={{ search: query }}
            onTableAction={handleAction}
          />
        )}

        {activeTab === 'requests' && (
          <InvestmentsTable
            activeTab={activeTab}
            prevTab={prevTabRef.current}
            data={investmentsRequests}
            loading={!investmentsRequestsAreLoaded}
            location={location}
            history={history}
            getList={getRequestsList}
            onRowClick={handleRequestsRowClick}
            tempNewInvestDisableCanselRequestButton
            params={{ search: query }}
            onTableAction={handleAction}
            isNewInevest
          />
        )}

        <BasicModal isOpen={isOpen} captionId={modalTitle(activeModal.type)} onRequestClose={toggleModal}>
          {activeModal.type === 'deposit' && (
            <InvestmentDepositFormNew
              accounts={accounts}
              strategyId={activeModal.strategyId}
              isWallet={isWallet}
              strategyAccountId={activeModal.strategyAccountId}
              strategyCurrency={activeModal.strategyCurrency}
              walletBalance={profile.walletBalance}
              toggleModal={toggleModal}
              investmentId={activeModal.id}
              initialValues={{ strategy: activeModal.title }}
            />
          )}
          {activeModal.type === 'withdraw' && (
            <InvestWithdrawFormNew
              accounts={accounts}
              strategyId={activeModal.strategyId}
              isWallet={isWallet}
              investmentId={activeModal.id}
              toggleModal={toggleModal}
              dispatch={dispatch}
            />
          )}
          {activeModal.type === 'close' && (
            <InvestCloseFormNew
              strategyId={activeModal.strategyId}
              accounts={accounts}
              isWallet={isWallet}
              toggleModal={toggleModal}
              investmentId={activeModal.id}
            />
          )}
        </BasicModal>
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestInvesmentsNew.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      profile: state.user.profile,
      accounts: state.client.common.accounts.filter(
        account => !account.isArchived && !account.isDemo && !account.isBlocked && account.balance
      ),
      investments: state.client.investNew.investments,
      investmentsAreLoaded: state.client.investNew.investmentsAreLoaded,
      investmentsRequests: state.client.investNew.investmentsRequests,
      investmentsRequestsAreLoaded: state.client.investNew.investmentsRequestsAreLoaded,
      addons: state.interfaceConfig.addons,
    }),
    {
      getInvestmentsNew: data => getInvestmentsNew.request(data),
      getInvestmentsRequestsNew: data => getInvestmentsRequestsNew.request(data),
      setInvestmentsActionNew: data => setInvestmentsActionNew.request(data),
      closeInvestmentRequestNew: id => closeInvestmentRequestNew.request(id),
    }
  )
)(InvestInvesmentsNew);
