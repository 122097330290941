import React, { useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getOverdueTasks, getTodayTasks, getTomorrowTasks } from 'crm/tasks/_redux';
import MyTasksTable from '../MyTasksTable';

import { staticStyles } from './style';

const MyTasks = ({
  overdueTasks,
  overdueTasksAreLoaded,
  todayTasks,
  todayTasksAreLoaded,
  tomorrowTasks,
  tomorrowTasksAreLoaded,
  getOverdueTasks,
  getTodayTasks,
  getTomorrowTasks,
  history,
}) => {
  useEffect(() => {
    getOverdueTasks({ search: { page: 1, page_size: 5 } });
    getTodayTasks({ search: { page: 1, page_size: 5 } });
    getTomorrowTasks({ search: { page: 1, page_size: 5 } });
  }, []);
  const today = moment().format('DD.MM.YYYY');
  const yesterday = moment().add(-1, 'days').format('DD.MM.YYYY');
  const tomorrow = moment().add(1, 'days').format('DD.MM.YYYY');

  return (
    <div className="MyTasks">
      <div className="MyTasks__title-date-wrap">
        <FM id="justOverdueTasks"> {txt => <h3 className="MyTasks__title">{txt}</h3>}</FM>

        <div className="MyTasks__date">{yesterday}</div>
      </div>
      <div className="MyTasks__table-overdue">
        <MyTasksTable
          data={overdueTasks}
          getData={getOverdueTasks}
          dataIsLoaded={overdueTasksAreLoaded}
          onRowClick={({ rowData: { subject } }) => history.push(`/crm/contacts/${subject}`)}
        />
      </div>
      <div className="MyTasks__title-date-wrap">
        <FM id="justTodayTasks"> {txt => <h3 className="MyTasks__title">{txt}</h3>}</FM>
        <div className="MyTasks__date">{today}</div>
      </div>
      <div className="MyTasks__table-today">
        <MyTasksTable getData={getTodayTasks} data={todayTasks} dataIsLoaded={todayTasksAreLoaded} />
      </div>
      <div className="MyTasks__title-date-wrap">
        <FM id="justTomorrowTasks"> {txt => <h3 className="MyTasks__title">{txt}</h3>}</FM>
        <div className="MyTasks__date">{tomorrow}</div>
      </div>
      <div className="MyTasks__table-tomorrow">
        <MyTasksTable getData={getTomorrowTasks} data={tomorrowTasks} dataIsLoaded={tomorrowTasksAreLoaded} />
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

MyTasks.propTypes = {};

export default compose(
  withRouter,
  connect(
    state => ({
      overdueTasks: state.crm.tasks.overdueTasks,
      overdueTasksAreLoaded: state.crm.tasks.overdueTasksAreLoaded,
      todayTasks: state.crm.tasks.todayTasks,
      todayTasksAreLoaded: state.crm.tasks.todayTasksAreLoaded,
      tomorrowTasks: state.crm.tasks.tomorrowTasks,
      tomorrowTasksAreLoaded: state.crm.tasks.tomorrowTasksAreLoaded,
    }),
    {
      getOverdueTasks: search => getOverdueTasks.request(search),
      getTodayTasks: search => getTodayTasks.request(search),
      getTomorrowTasks: search => getTomorrowTasks.request(search),
    }
  )
)(MyTasks);
