import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import { isValidNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';

import { DefaultButton, PhoneField, ReduxTextField } from 'common/components';

import { createUser } from '../../_redux';

import staticStyles from './style';

const validatePhoneNumber = phoneNumber =>
  !isValidNumber(phoneNumber) ? <FormattedMessage id="validationErrorWrongPhone" /> : null;

const UserCreateForm = ({ toggleModal, handleSubmit, dispatch, submitting, invalid, pristine, error }) => {
  const submit = handleSubmit(values => createUser({ ...values }, dispatch));

  return (
    <form className="UserCreateForm" onSubmit={submit}>
      <div className="UserCreateForm__inputs-inner">
        <Field
          component={ReduxTextField}
          type="text"
          name="firstName"
          textId="justFirstName"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          type="text"
          name="lastName"
          textId="justLastName"
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <Field
        component={ReduxTextField}
        type="email"
        name="email"
        textId="authEmail"
        validate={[required(), email()]}
        disabled={submitting}
      />
      <Field
        component={PhoneField}
        name="phone"
        textId="justPhone"
        validate={[required(), validatePhoneNumber]}
        disabled={submitting}
      />

      <div className="UserCreateForm__actions">
        <DefaultButton
          textId="justCreate"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={() => toggleModal()} />
      </div>

      {error && <strong className="formError">{error}</strong>}

      <style jsx>{staticStyles}</style>
    </form>
  );
};

UserCreateForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

UserCreateForm.defaultProps = {
  error: '',
};

export default reduxForm({
  form: 'user-create-form',
  onSubmitSuccess: (result, dispatch, { handleGetUsers, toggleModal, showModal }) => {
    handleGetUsers();
    toggleModal();
    showModal({
      caption: { id: 'boUserCreateSuccessMessageTitle' },
      content: { id: 'boUserCreateSuccessMessageText' },
      actionButton: null,
      cancelButton: {
        textId: 'justOk',
      },
    });
  },
})(UserCreateForm);
