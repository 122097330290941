import React, { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { PageLayout, PanelTabs, PageContentPreloader, DefaultButton } from 'common/components';
import { DashboardsPage } from 'common/dashboards';

import {
  UserInfo,
  UserAccountsPanel,
  UserOperations,
  UserBonuses,
  UserHistory,
  UserNotes,
  UserPartnerAccountsPanel,
  UserIssues,
  UserMarks,
  UserKYC,
  UserTrederoCustomKYC,
} from '../../_components';
import { getUserData, startImpersonate } from '../../_redux';

import { staticStyles } from './style';

const UserPage = ({
  match,
  history,
  location,
  profile,
  user,
  userIsLoaded,
  getUserData,
  startImpersonate,
  addons,
  redirect,
}) => {
  const activeTabSlug = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : 'info'),
    [location.search]
  );

  useEffect(() => {
    getUserData(match.params.id);
  }, [getUserData, match.params.id]);

  if (redirect) {
    return <Redirect to="/backoffice" />;
  }

  return (
    <PageLayout captionSlug="boUser" extraCaptionText={match.params.id} showReload={false}>
      {(profile.perms.impersonateUser || profile.perms.managerImpersonateUser) &&
        user.email !== profile.email &&
        !user.isAdmin && (
          <div className="UserPage__impersonate">
            <DefaultButton
              textId="loginAsUser"
              filled
              onClick={() => {
                startImpersonate({ userId: match.params.id });
                window.location.replace('/');
              }}
            />
          </div>
        )}
      <PageContentPreloader ready={userIsLoaded} type="boUserInfo">
        <PanelTabs activeTabSlug={activeTabSlug} location={location} history={history}>
          <UserInfo textId="boUserInfo" slug="info" match={match} />
          <UserAccountsPanel textId="boUserAccounts" slug="accounts" id={match.params.id} />
          {user.isPartner && (
            <UserPartnerAccountsPanel textId="boUserPartnerAccounts" slug="partner_accounts" id={match.params.id} />
          )}
          {profile.perms.viewDashboards && user.isPartner && (
            <DashboardsPage textId="boDashboards" slug="dashboards" match={match} partnerId={user.id} />
          )}
          {profile.perms.viewDeposit && profile.perms.viewWithdraw && (
            <UserOperations textId="userPayments" slug="payments" />
          )}
          {!addons.includes('trederoCustomKyc') && profile.perms.changeKYC && <UserKYC textId="justKYC" slug="kyc" />}
          {addons.includes('trederoCustomKyc') && profile.perms.changeKYC && (
            <UserTrederoCustomKYC textId="justKYC" slug="kyc" />
          )}
          {addons.includes('bonusesSystem') && (profile.perms.viewBonuses || profile.perms.managerViewBonuses) && (
            <UserBonuses textId="boBonuses" slug="bonuses" />
          )}
          <UserHistory textId="boUserHistory" slug="history" id={match.params.id} />
          {(profile.perms.notesUser || profile.perms.managerNotesUser) && (
            <UserNotes textId="boUserNotes" slug="notes" id={match.params.id} />
          )}
          {profile.perms.fullAccessSupport && <UserIssues textId="boIssues" slug="issues" id={match.params.id} />}
          <UserMarks textId="boUtmMarks" slug="marks" id={match.params.id} />
        </PanelTabs>
      </PageContentPreloader>

      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

UserPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userIsLoaded: PropTypes.bool.isRequired,
  getUserData: PropTypes.func.isRequired,
  startImpersonate: PropTypes.func.isRequired,
  addons: PropTypes.array.isRequired,
  redirect: PropTypes.bool,
};

UserPage.defaultProps = {
  redirect: false,
};

const ConnectedUserPage = connect(
  state => ({
    profile: state.user.profile,
    user: state.backoffice.users.user,
    userIsLoaded: state.backoffice.users.userIsLoaded,
    addons: state.interfaceConfig.addons,
    redirect: state.backoffice.common.redirect,
  }),
  {
    getUserData: id => getUserData.request(id),
    startImpersonate: data => startImpersonate.request(data),
  }
)(UserPage);

export default ConnectedUserPage;
