import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { CheckboxField, DateRangeField, SwitchField } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getTradingAccountTypes } from 'client/_redux';
import { required } from 'redux-form-validators';
import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() + i,
  label: moment().year() + i,
}));

const BonusDisplayPanel = ({
  currentLang,
  availableLangs,
  accountTypes,
  getAccountTypes,
  selectedBonusType,
  submitting,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const isRealAccount = title => title === 'Real account';

  useEffect(() => {
    getAccountTypes();
  }, [getAccountTypes]);

  return (
    <div className="BonusDisplayPanel">
      <FormattedMessage id="justBonusDurationAndDisplay">
        {txt => <div className="BonusDisplayPanel__caption">{txt}</div>}
      </FormattedMessage>
      <div className="BonusDisplayPanel__top-wrap">
        <div className="BonusDisplayPanel__top-left">
          <div className="BonusDisplayPanel__top-left-title">
            <FormattedMessage id="justBonusDuration">{txt => txt}</FormattedMessage>
          </div>
          <Field
            component={DateRangeField}
            name="startEndDate"
            disabled={submitting}
            validate={[required()]}
            yearsOptions={yearsOptions}
            areFutureDaysAvailable
          />
        </div>
        <div className="BonusDisplayPanel__top-right">
          <span className="BonusDisplayPanel__title BonusDisplayPanel__top-right-title">
            <FormattedMessage id="justHideBonus">{txt => txt}</FormattedMessage>
          </span>
          <Field component={SwitchField} name="isActive" type="checkbox" disabled={submitting} withText />
        </div>
      </div>
      <div className="BonusDisplayPanel__lang-title">
        <FormattedMessage id="justDisplayLanguages">{txt => txt}</FormattedMessage>
      </div>
      <div className="BonusDisplayPanel__line">
        <FormSection name="availableLanguages" className="BonusDisplayPanel__checkbox-section">
          {availableLangs.map(language => (
            <Field
              key={language.slug}
              component={CheckboxField}
              name={language.slug}
              text={language.niceName}
              disabled={currentLang === language.slug}
            />
          ))}
        </FormSection>
      </div>
      <div className="BonusDisplayPanel__available-accounts">
        <FormattedMessage id="justAvailableForAccounts">{txt => txt}</FormattedMessage>
      </div>
      <div className="BonusDisplayPanel__line">
        <FormSection
          name="availableForAccountTypes"
          className="BonusDisplayPanel__checkbox-section BonusDisplayPanel__available-accounts-list">
          {accountTypes
            .filter(account => selectedBonusType !== 'bonus_risk_free' || account.platform.slug === 'bo')
            .map(account => {
              const text = `${isRealAccount(account.title) ? account.description : account.title}`;

              return <Field key={account.id} component={CheckboxField} name={`${account.id}`} text={text} />;
            })}
        </FormSection>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

BonusDisplayPanel.propTypes = {
  submitting: PropTypes.bool.isRequired,
  currentLang: PropTypes.string.isRequired,
  availableLangs: PropTypes.array.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  selectedBonusType: PropTypes.string,
  accountTypes: PropTypes.array,
  theme: PropTypes.object,
};

BonusDisplayPanel.defaultProps = {
  accountTypes: [],
  selectedBonusType: '',
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      accountTypes: state.client.common.accountTypes.filter(account => !account.isDemo),
      availableLangs: state.interfaceConfig.availableLangs,
      currentLang: state.interfaceConfig.lang,
    }),
    {
      getAccountTypes: () => getTradingAccountTypes.request(),
    }
  )
)(BonusDisplayPanel);
export { BonusDisplayPanel };
