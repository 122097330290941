import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .PartnerAccountsTable {
    .ReactTable {
      width: 100%;
      border: none;

      .rt-thead.-header {
        .rt-th {
          &:nth-child(4),
          &:nth-child(5) {
            @include respond-to(sm) {
              display: none;
            }
          }
        }
      }

      .rt-tbody {
        .rt-tr-group {
          .rt-tr {
            margin: 0 0;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;
            border: none;
            padding: rem(10px 18px);
            @include respond-to(lg) {
              padding: rem(10px);
            }
            .rt-td {
              padding: 0;
              margin: 0 3px 0 0;

              &:nth-child(4),
              &:nth-child(5) {
                @include respond-to(sm) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { staticStyles };
