import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  countries: [],
  countriesAreLoaded: false,

  states: [],
  statesAreLoaded: false,

  kycValues: {},
  kycValuesAreLoaded: false,

  documentsReupload: {
    identification_proof: false,
    passport: false,
    address_proof: false,
    additional_document: false,
  },
  documentsUploadErrors: {},

  notificationSettings: {},
  notificationSettingsAreLoaded: false,

  agreements: [],
  agreementsAreLoaded: false,

  cards: [],
  cardsAreLoaded: false,

  card: {},
  cardIsLoaded: false,

  deleteCardError: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COUNTRIES[REQUEST]:
      return { ...state, countriesAreLoaded: false };
    case ActionTypes.GET_COUNTRIES[SUCCESS]:
      return { ...state, countries: action.data, countriesAreLoaded: true };
    case ActionTypes.GET_COUNTRIES[FAILURE]:
      return { ...state, error: action.error, countriesAreLoaded: false };

    case ActionTypes.GET_STATES[REQUEST]:
      return { ...state, statesAreLoaded: false };
    case ActionTypes.GET_STATES[SUCCESS]:
      return { ...state, states: action.data, statesAreLoaded: true };
    case ActionTypes.GET_STATES[FAILURE]:
      return { ...state, error: action.error, statesAreLoaded: false };

    case ActionTypes.GET_KYC[REQUEST]:
      return { ...state, kycValuesAreLoaded: false };
    case ActionTypes.GET_KYC[SUCCESS]: {
      const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
      sections.forEach(section => {
        if (!!action.data.experience[section] && Object.keys(action.data.experience[section]).length > 0) {
          action.data.experience[section].checkbox = true;
        }
      });
      return { ...state, kycValuesAreLoaded: true, kycValues: action.data };
    }
    case ActionTypes.GET_KYC[FAILURE]:
      return { ...state, kycValuesAreLoaded: false };

    case ActionTypes.SEND_DOCUMENTS[REQUEST]:
      return { ...state };
    case ActionTypes.SEND_DOCUMENTS[SUCCESS]: {
      const errors = { ...state.documentsUploadErrors };
      delete errors[action.name];
      const reupload = { ...state.documentsReupload };
      reupload[action.name] = false;
      return {
        ...state,
        documentsUploadErrors: errors,
        documentsReupload: reupload,
      };
    }
    case ActionTypes.SEND_DOCUMENTS[FAILURE]:
      return {
        ...state,
        documentsUploadErrors: {
          ...state.documentsUploadErrors,
          ...action.error,
        },
      };

    case ActionTypes.TOGGLE_REUPLOAD_DOCUMENTS: {
      const toggleReupload = { ...state.documentsReupload };
      toggleReupload[action.name] = !toggleReupload[action.name];
      return { ...state, documentsReupload: toggleReupload };
    }

    case ActionTypes.GET_NOTIFICATION_SETTINGS[REQUEST]:
      return { ...state, notificationSettingsAreLoaded: false };
    case ActionTypes.GET_NOTIFICATION_SETTINGS[SUCCESS]:
      return {
        ...state,
        notificationSettings: action.data,
        notificationSettingsAreLoaded: true,
      };
    case ActionTypes.GET_NOTIFICATION_SETTINGS[FAILURE]:
      return {
        ...state,
        error: action.error,
        notificationSettingsAreLoaded: false,
      };

    case ActionTypes.GET_AGREEMENTS[REQUEST]:
      return { ...state, agreementsAreLoaded: false };
    case ActionTypes.GET_AGREEMENTS[SUCCESS]:
      return { ...state, agreements: action.data, agreementsAreLoaded: true };
    case ActionTypes.GET_AGREEMENTS[FAILURE]:
      return { ...state, error: action.error, agreementsAreLoaded: false };

    case ActionTypes.GET_CARDS[REQUEST]:
      return { ...state, cardsAreLoaded: false };
    case ActionTypes.GET_CARDS[SUCCESS]:
      return { ...state, cards: action.data, cardsAreLoaded: true };
    case ActionTypes.GET_CARDS[FAILURE]:
      return { ...state, error: action.error, cardsAreLoaded: false };

    case ActionTypes.GET_CARD[REQUEST]:
      return { ...state, cardIsLoaded: false };
    case ActionTypes.GET_CARD[SUCCESS]:
      return { ...state, card: action.data, cardIsLoaded: true };
    case ActionTypes.GET_CARD[FAILURE]:
      return { ...state, error: action.error, cardIsLoaded: false };

    case ActionTypes.DELETE_CARD[REQUEST]:
      return { ...state };
    case ActionTypes.DELETE_CARD[SUCCESS]:
      return { ...state };
    case ActionTypes.DELETE_CARD[FAILURE]:
      return { ...state, deleteCardError: action.error };

    default:
      return state;
  }
};

export default reducer;
