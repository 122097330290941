import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PageContentPreloader, PageLayout } from 'common/components';
import WarningMessage from 'client/_components/WarningMessage';
import { getAccountsPrefetch } from 'client/_redux/actions';
import { useDispatch } from 'react-redux';
import PaymentsForm from '../PaymentsForm';

export const PaymentsPanel = ({
  action,
  addons,
  initialLoading,
  paymentSystemList,
  loadPaymentSystems,
  getAvailableLpas,
  availableLpasList,
  getTradingAccountTypes,
  loadAccounts,
  match,
  captionSlug,
  loadAccount,
}) => {
  const dispatch = useDispatch();
  const [openStepLPA, setOpenStepLPA] = useState(false);

  useEffect(() => {
    loadPaymentSystems();
  }, [loadPaymentSystems]);

  useEffect(() => {
    if (openStepLPA) {
      getAvailableLpas();
    }
  }, [getAvailableLpas, openStepLPA]);

  useEffect(() => {
    if (getTradingAccountTypes) {
      getTradingAccountTypes();
    }
  }, [getTradingAccountTypes]);

  useEffect(() => {
    if (!addons.includes('walletAccount')) {
      loadAccounts();
    }
  }, [loadAccounts, addons]);

  useMemo(() => dispatch(getAccountsPrefetch.request()), [dispatch]);

  const initialValues = {};
  if (match.params.accountId) {
    initialValues.account = parseInt(match.params.accountId, 10);
  }

  const captionSlugNew =
    document.location.hostname === 'my.swaycapitalgroup.com' && captionSlug === 'clientDeposit'
      ? 'swayCapitalDeposit'
      : captionSlug;

  return (
    <PageLayout captionSlug={captionSlugNew}>
      <PageContentPreloader ready={!initialLoading} type="payments">
        {paymentSystemList.length ? (
          <PaymentsForm
            action={action}
            loadAccount={loadAccount}
            captionId="chooseYourPaymentOption"
            paymentSystemList={paymentSystemList}
            availableLpasList={availableLpasList}
            initialValues={initialValues}
            openStepLPA={openStepLPA}
            setOpenStepLPA={setOpenStepLPA}
          />
        ) : (
          <WarningMessage headerTextId="noPaymentSystems" />
        )}
      </PageContentPreloader>
    </PageLayout>
  );
};

PaymentsPanel.propTypes = {
  loadAccount: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  loadPaymentSystems: PropTypes.func.isRequired,
  loadAccounts: PropTypes.func.isRequired,
  paymentSystemList: PropTypes.array.isRequired,
  addons: PropTypes.array,
  initialLoading: PropTypes.bool,
  captionSlug: PropTypes.string.isRequired,
  getTradingAccountTypes: PropTypes.func,
  getAvailableLpas: PropTypes.func.isRequired,
  availableLpasList: PropTypes.array.isRequired,
};

PaymentsPanel.defaultProps = {
  addons: [],
  initialLoading: false,
  getTradingAccountTypes: null,
};

export default PaymentsPanel;
