import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { changeCurrentInterface } from '../../_redux/actions';

import { staticStyles, getDynamicStyles } from './style';

const InterfaceSwitcher = ({
  changeCurrentInterface,
  closeMenu,
  currentInterface,
  customJsIsActive,
  interfaces,
  history,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  const hiddenElementRef = useRef();
  const lessThan1149 = useMediaQuery({ query: '(max-width: 1149px)' });
  const lessThan1151 = useMediaQuery({ query: '(max-width: 1151px)' });

  const selectMenuElementWidth = useMemo(() => {
    // in case of hieroglyphs
    const elementWidth = hiddenElementRef?.current?.offsetWidth;
    if (!elementWidth) {
      return `200px`;
    }
    return `${elementWidth + 40}px`;
  }, [hiddenElementRef?.current?.offsetWidth, lessThan1149, lessThan1151]);

  const labelFormattedString = intl.formatMessage({ id: currentInterface.label });

  const goToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  // FIXME: интерфейс везде по умолчанию client

  const handleChangeInterface = currentInterface => {
    changeCurrentInterface(currentInterface.value);
    history.push(currentInterface.mainUrl);
    goToTop();

    if (closeMenu) {
      closeMenu();
    }

    if (customJsIsActive) window.location.reload();
  };

  return (
    <div className="TopMenu__switch-interface-inner">
      <span className="TopMenu__hidden-element" ref={hiddenElementRef}>
        {labelFormattedString}
      </span>
      <Select
        value={{
          value: currentInterface.slug,
          label: labelFormattedString,
        }}
        classNamePrefix="Select"
        placeholder=""
        hideSelectedOptions
        onChange={handleChangeInterface}
        options={interfaces.map(i => ({
          value: i.slug,
          label: intl.formatMessage({ id: i.label }),
          mainUrl: i.mainUrl,
        }))}
        isClearable={false}
        isSearchable={false}
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
      <style jsx global>
        {`
          @import 'src/common/styling/_mixins-scss/screen-size';

          .TopMenu__switch-interface-inner {
            width: ${selectMenuElementWidth} !important;
          }
        `}
      </style>
    </div>
  );
};

export const userInterfacePT = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mainUrl: PropTypes.string.isRequired,
});

InterfaceSwitcher.propTypes = {
  currentInterface: PropTypes.object.isRequired,
  interfaces: PropTypes.arrayOf(userInterfacePT).isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  changeCurrentInterface: PropTypes.func.isRequired,
  closeMenu: PropTypes.func,
  customJsIsActive: PropTypes.bool.isRequired,
};

InterfaceSwitcher.defaultProps = {
  closeMenu: null,
  theme: {},
};

export default compose(
  withTheme(),
  injectIntl,
  withRouter,
  connect(null, {
    changeCurrentInterface: data => changeCurrentInterface(data),
  })
)(InterfaceSwitcher);
export { InterfaceSwitcher };
