import { watchers as commonWatchers } from './commonSagas';
import { watchers as accWatchers } from '../accounts';
import { watchers as messWatchers } from '../messages';
import { watchers as suppWatchers } from '../support';
import { watchers as payWatchers } from '../payments';
import { watchers as kycWatchers } from '../KYC';
import { watchers as partnerWatchers } from '../partnership';
import { watchers as articlesWatchers } from '../articles';
import { watchers as filesWatchers } from '../files';
import { watchers as transfersWatchers } from '../transfers';
import { watchers as bonusesWatchers } from '../bonuses';
import { watchers as faqWatchers } from '../faq';
import { watchers as infoWatchers } from '../information';
import { watchers as statusesWatchers } from '../statuses';
import { watchers as investWatchers } from '../invest';
import { watchers as investNewWatchers } from '../investNew';

export default [
  ...commonWatchers,
  ...accWatchers,
  ...messWatchers,
  ...suppWatchers,
  ...payWatchers,
  ...kycWatchers,
  ...partnerWatchers,
  ...articlesWatchers,
  ...filesWatchers,
  ...transfersWatchers,
  ...bonusesWatchers,
  ...faqWatchers,
  ...infoWatchers,
  ...statusesWatchers,
  ...investWatchers,
  ...investNewWatchers,
];
