import axios from 'axios';

const geonamesAPI = axios.create({ baseURL: '' });

export const getCountries = () => geonamesAPI.get('/geonames/country/');

export const getStates = gid => geonamesAPI.get(`/geonames/country/${gid}/regions/`);

export const getKYC = () => axios.get('/user/kyc_form/');
export const sendKYC = payload => axios.put('/user/kyc_form/', { ...payload });

export const sendDocuments = (name, files) => {
  const data = new FormData();
  const config = { headers: { 'content-type': 'multipart/form-data' } };

  files.forEach(file => {
    data.append('file', file);
  });

  return axios.post(`/user/document/${name}/`, data, config);
};

export const changePassword = payload => axios.post(`/auth/change_password/`, { ...payload });

export const getNotificationSettings = () => axios.get('/user/notification_settings/');

export const changeNotificationSettings = payload => axios.post('/user/notification_settings/', { ...payload });

export const getAgreements = () => axios.get(`/common/agreements/`);

export const getCards = () => axios.get(`/cards/list/`);

export const getCard = id => axios.get(`/cards/${id}/`);

export const deleteCard = id => axios.delete(`/cards/${id}/`);

export const updateCard = (cardId, formData) =>
  axios.put(`/cards/${cardId}/`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const addCard = data => {
  const config = { headers: { 'content-type': 'multipart/form-data' } };
  return axios.post(`/cards/add/`, data, config);
};
