import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { FilterCreate, FilterEdit, FastFilters } from 'crm/_newComponents';

import {
  // getCRMManagersFiltersList,
  getCRMManagersFiltersOptions,
  createCRMManagersCustomFilter,
  deleteCRMManagersCustomFilter,
  editCRMManagersCustomFilter,
  getCRMManagersFilter,
} from 'crm/analytics/_redux';

import {
  BasicCRMModal,
  //  CustomFilterSelect,
  BaseButton,
  SearchBar,
} from 'crm/_components';
import { staticStyles } from './style';

const ManagersActionsTab = ({
  crmManagersFilter,
  crmManagersFilterIsLoaded,
  filtersManagersOptions,
  filtersOptionsManagersPending,
  location,
  history,
  // intl,
}) => {
  const dispatch = useDispatch();
  // const initialFilter = { value: '', label: intl.formatMessage({ id: 'crmCustomFilter' }) };
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  // const [filter, setFilter] = useState(initialFilter);
  const [filterId, setFilterId] = useState(null);
  const query = useMemo(() => ({ ...queryString.parse(location.search) }), [location.search]);
  const toggleModal = () => {
    setIsOpen(isOpen => !isOpen);
  };
  useEffect(() => {
    setFilterId(queryString.parse(location.search).filterId);
  }, [location.search]);
  const handleSearchChange = searchValue => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...query,
        search: searchValue,
      }),
    });
  };
  // const handleFilterChange = value => {
  //   if (value.value === 'create') {
  //     toggleModal();
  //   } else {
  //     setFilter(value);
  //     history.replace({
  //       search: queryString.stringify({
  //         ...query,
  //         filterId: value.value,
  //       }),
  //     });
  //   }
  // };

  const toggleEditModal = id => {
    setEditId(id);
    setIsEditOpen(isEditOpen => !isEditOpen);
  };
  const toggleDeleteModal = id => {
    setDeleteId(id);
    setIsDeleteOpen(isEditOpen => !isEditOpen);
  };
  const deleteCustomFilter = id => {
    deleteCRMManagersCustomFilter(id, dispatch);
  };
  const handleResetFilter = () => {
    // setFilter(initialFilter);
    history.replace('/crm/analytics?tab=crmManagers');
  };

  return (
    <div className="ManagersActionsTab">
      <div className="ManagersActionsTab__Top">
        <div className="ManagersActionsTab__TopLeft">
          <SearchBar onChange={handleSearchChange} />
        </div>
        {/* TODO: Как на беке сделают просто раскоментировать */}
        {/* <div className="ManagersActionsTab__TopRight">
          <CustomFilterSelect
            onChange={handleFilterChange}
            toggleEditModal={toggleEditModal}
            toggleDeleteModal={toggleDeleteModal}
            onResetFilter={handleResetFilter}
            filter={filter}
            filterId={filterId}
            filtersSelector={state => state.crm.analytics.filtersList}
            getFilters={getCRMManagersFiltersList}
          />
        </div> */}
      </div>
      <FastFilters
        getFastFiltersFunc={getCRMManagersFiltersOptions}
        fastFiltersSelector={state => state.crm.analytics.filtersOptions}
      />

      <BasicCRMModal isOpen={isOpen} captionId="crmCustomFilter" onRequestClose={toggleModal}>
        <FilterCreate
          onRequestClose={toggleModal}
          getFiltersOptions={getCRMManagersFiltersOptions}
          filtersOptionsSelector={state => state.crm.analytics.filtersOptions}
          filtersOptionsPendingSelector={state => state.crm.analytics.filtersOptionsPending}
          createCustomFilterSubmitFunc={createCRMManagersCustomFilter}
        />
      </BasicCRMModal>
      <BasicCRMModal isOpen={isEditOpen} captionId="crmEditCustomFilter" onRequestClose={toggleEditModal}>
        <FilterEdit
          editCrmCustomFilter={editCRMManagersCustomFilter}
          getCRMFiltersOptions={getCRMManagersFiltersOptions}
          getCrmFilter={getCRMManagersFilter}
          filtersOptions={filtersManagersOptions}
          filtersOptionsPending={filtersOptionsManagersPending}
          crmFilter={crmManagersFilter}
          crmFilterIsLoaded={crmManagersFilterIsLoaded}
          onRequestClose={toggleEditModal}
          id={editId}
        />
      </BasicCRMModal>
      <BasicCRMModal isOpen={isDeleteOpen} captionId="confirmText" onRequestClose={toggleDeleteModal}>
        <div className="ActionsTab__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteOpen(false);
            }}
          />
          <BaseButton
            color="red"
            textId="justDelete"
            onClick={() => {
              deleteCustomFilter(deleteId);
              setIsDeleteOpen(false);
              if (+filterId === +deleteId) {
                handleResetFilter();
              }
            }}
          />
        </div>
      </BasicCRMModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ManagersActionsTab.propTypes = {
  filtersManagersOptions: PropTypes.object.isRequired,
  filtersOptionsManagersPending: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  // intl: PropTypes.object.isRequired,
  crmManagersFilter: PropTypes.object.isRequired,
  crmManagersFilterIsLoaded: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      filtersManagersOptions: state.crm.analytics.filtersOptions,
      filtersOptionsManagersPending: state.crm.analytics.filtersOptionsPending,
      crmManagersFilter: state.crm.analytics.crmFilter,
      crmManagersFilterIsLoaded: state.crm.analytics.crmFilterIsLoaded,
    }),
    {
      getCRMManagersFiltersOptions: () => getCRMManagersFiltersOptions.request(),
      getCRMManagersFilter: id => getCRMManagersFilter.request(id),
    }
  )
)(ManagersActionsTab);
