import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { StatusCell, PaginationTable, DateCell } from 'common/components/index';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const TransfersTable = ({
  data,
  onRowClick,
  getList,
  loading,
  history,
  location,
  params,
  theme,
  intl,
  hasPaymentOperationManagers,
}) => {
  const TRANSFER_TYPE_SLUGS = {
    INNER: 'boInnerTransfer',
    TO_TRADER: 'boAnotherTrader',
    FROM_WALLET: 'fromWallet',
    TO_WALLET: 'toWallet',
    TO_TRADER_WALLET: 'toTraderWallet',
    TO_TRADER_LPA: 'justToLpa',
    TO_TRADER_WALLET_LPA: 'justToWalletLpa',
  };

  /* eslint-disable */
  const columns = [
    { key: 'boId', accessor: 'id', isPreviewCell: true },
    {
      key: 'accountFrom',
      accessor: 'senderAccount',
      Cell: ({ original: { senderAccount, email } }) => (
        <span className="TransfersTable__sender-account">
          {senderAccount || email || intl.formatMessage({ id: 'menuWallet' })}
        </span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'accountTo',
      accessor: 'recipientAccount',
      Cell: ({ original: { recipientAccount, recipient } }) => (
        <span className="TransfersTable__sender-account">
          {recipientAccount || recipient || intl.formatMessage({ id: 'menuWallet' })}
        </span>
      ),
    },
    {
      key: 'boStatus',
      accessor: 'status',
      Cell: ({ original: { status } }) => <StatusCell statusCode={status} />,
      isPreviewCell: true,
    },
    {
      key: 'boAmount',
      Cell: ({ original: { sendAmount } }) => (
        <span className="TransfersTable__amount">
          {sendAmount.amount} {sendAmount.currency}
        </span>
      ),
      isPreviewCell: true,
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            key: 'justManager',
            Cell: ({ original: { manager } }) => (
              <div>{manager ? `${manager.firstName} ${manager.lastName}` : '一'}</div>
            ),
          },
        ]
      : []),
    {
      key: 'boTransferType',
      Cell: ({ original: { transferType } }) => (
        <FormattedMessage id={TRANSFER_TYPE_SLUGS[transferType]}>
          {txt => <span className="TransfersTable__transferType">{txt}</span>}
        </FormattedMessage>
      ),
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="TransfersTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TransfersTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
};

TransfersTable.defaultProps = {
  theme: {},
};

export default compose(injectIntl, withTheme())(TransfersTable);
export { TransfersTable };
