import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { PageLayout, PageContentPreloader } from 'common/components';
import { UserTradingHistory } from 'backoffice/_components';
import { AccountInfoPanel } from '../../_components';

import {
  getUserAccount,
  blockUserAccount,
  unblockUserAccount,
  deleteUserAccount,
  restoreUserAccount,
} from '../../_redux';

const AccountPage = ({
  account,
  accountIsLoaded,
  getUserAccount,
  blockUserAccount,
  unblockUserAccount,
  deleteUserAccount,
  restoreUserAccount,
  partnershipPermission,
  equityTradingHistoryDisplay,
  redirect,
  match,
}) => {
  const { userId, accountId } = match.params;

  useEffect(() => {
    getUserAccount(userId, accountId);
  }, [getUserAccount, userId, accountId]);

  if (redirect) {
    return <Redirect to="/backoffice/accounts" />;
  }

  return (
    <PageLayout captionSlug="boAccount" extraCaptionText={account && account.login ? ` #${account.login}` : ''}>
      <PageContentPreloader ready={accountIsLoaded}>
        {!!account.id && (
          <AccountInfoPanel
            {...account}
            userId={Number(userId)}
            blockFunc={blockUserAccount}
            unBlockFunc={unblockUserAccount}
            deleteFunc={deleteUserAccount}
            restoreFunc={restoreUserAccount}
            getUserAccount={getUserAccount}
            partnershipPermission={partnershipPermission}
            equityTradingHistoryDisplay={equityTradingHistoryDisplay}
          />
        )}
        <UserTradingHistory userId={Number(userId)} accountId={Number(accountId)} accountLabel={account.login} opened />
      </PageContentPreloader>
    </PageLayout>
  );
};

AccountPage.propTypes = {
  account: PropTypes.object.isRequired,
  accountIsLoaded: PropTypes.bool.isRequired,
  getUserAccount: PropTypes.func.isRequired,
  blockUserAccount: PropTypes.func.isRequired,
  unblockUserAccount: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  restoreUserAccount: PropTypes.func.isRequired,
  equityTradingHistoryDisplay: PropTypes.object.isRequired,
  partnershipPermission: PropTypes.bool.isRequired,
  redirect: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default withTheme()(
  connect(
    state => ({
      account: state.backoffice.users.account,
      accountIsLoaded: state.backoffice.users.accountIsLoaded,
      equityTradingHistoryDisplay: state.interfaceConfig.equityTradingHistoryDisplay,
      redirect: state.backoffice.common.redirect,
      partnershipPermission: state.user.profile.perms.partnershipUser,
    }),
    {
      getUserAccount: (userId, accountId) => getUserAccount.request(userId, accountId),
      blockUserAccount: (userId, accountId) => blockUserAccount.request(userId, accountId),
      unblockUserAccount: (userId, accountId) => unblockUserAccount.request(userId, accountId),
      deleteUserAccount: (userId, accountId) => deleteUserAccount.request(userId, accountId),
      restoreUserAccount: (userId, accountId) => restoreUserAccount.request(userId, accountId),
    }
  )(AccountPage)
);
export { AccountPage };
