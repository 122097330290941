import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  paymentMethodTemplates: [],
  paymentMethodTemplatesAreLoaded: false,

  paymentMethodsSettings: [],
  paymentMethodsSettingsIsLoaded: false,

  paymentMethodsList: [],
  paymentMethodsListIsLoaded: false,

  paymentMethod: {},
  paymentMethodIsLoaded: false,

  countries: [],
  countriesAreLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PAYMENT_METHODS_TEMPLATES[REQUEST]:
      return { ...state, paymentMethodsTemplatesAreLoaded: false };
    case ActionTypes.GET_PAYMENT_METHODS_TEMPLATES[SUCCESS]:
      return { ...state, paymentMethodsTemplates: action.data, paymentMethodsTemplatesAreLoaded: true };
    case ActionTypes.GET_PAYMENT_METHODS_TEMPLATES[FAILURE]:
      return { ...state, error: action.error, paymentMethodsTemplatesAreLoaded: false };

    case ActionTypes.GET_PAYMENT_METHODS_SETTINGS[REQUEST]:
      return { ...state, paymentMethodsSettingsIsLoaded: false };
    case ActionTypes.GET_PAYMENT_METHODS_SETTINGS[SUCCESS]:
      return { ...state, paymentMethodsSettings: action.data, paymentMethodsSettingsIsLoaded: true };
    case ActionTypes.GET_PAYMENT_METHODS_SETTINGS[FAILURE]:
      return { ...state, error: action.error, paymentMethodsSettingsIsLoaded: false };

    case ActionTypes.GET_PAYMENT_METHODS_LIST[REQUEST]:
      return { ...state, paymentMethodsListIsLoaded: false };
    case ActionTypes.GET_PAYMENT_METHODS_LIST[SUCCESS]:
      return { ...state, paymentMethodsList: action.data, paymentMethodsListIsLoaded: true };
    case ActionTypes.GET_PAYMENT_METHODS_LIST[FAILURE]:
      return { ...state, error: action.error, paymentMethodsListIsLoaded: false };

    case ActionTypes.GET_PAYMENT_METHOD_SETTINGS[REQUEST]:
      return { ...state, paymentMethodIsLoaded: false };
    case ActionTypes.GET_PAYMENT_METHOD_SETTINGS[SUCCESS]:
      return { ...state, paymentMethod: action.data, paymentMethodIsLoaded: true };
    case ActionTypes.GET_PAYMENT_METHOD_SETTINGS[FAILURE]:
      return { ...state, error: action.error, paymentMethodIsLoaded: false };

    case ActionTypes.GET_COUNTRIES[REQUEST]:
      return { ...state, countriesAreLoaded: false };
    case ActionTypes.GET_COUNTRIES[SUCCESS]:
      return { ...state, countries: action.data, countriesAreLoaded: true };
    case ActionTypes.GET_COUNTRIES[FAILURE]:
      return { ...state, error: action.error, countriesAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
