import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ModalHOC } from 'common';
import { EditIcon, BasketIcon } from 'common/icons';
import { PaginationTable, ActionsButton, MoneyCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BonusManagementTable = ({
  data,
  onRowClick,
  getList,
  handleDelete,
  showModal,
  loading,
  location,
  history,
  params,
  theme,
}) => {
  const editBonusesClick = (e, id) => {
    e.stopPropagation();
    history.push(`/backoffice/bonuses/${id}/edit`);
  };

  const deleteBonusesClick = (e, id) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmDeleteBonus' },
      content: {
        id: 'confirmText',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => handleDelete({ id, queryParams: params }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justAvatar',
      accessor: 'logo',
      Cell: ({ original: { logo } }) => <img src={logo} alt="logo" className="BonusManagementTable__logo" />,
      isPreviewCell: true,
    },
    {
      key: 'justTitle',
      accessor: 'title',
      Cell: ({ original: { title } }) => <span className="title">{title}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justType',
      accessor: 'type',
      Cell: ({ original: { type, defaultBonusType } }) => (
        <div className="BonusManagementTable__types">
          <span className="type">{type}</span>
          <FormattedMessage id={defaultBonusType === 'balance' ? 'justBalance' : 'justCredit'}>
            {txt => <span className="defaultType">{txt}</span>}
          </FormattedMessage>
        </div>
      ),
      isPreviewDescriptionCell: true,
    },
    {
      key: 'justValue',
      accessor: 'value',
      Cell: ({ original: { value } }) => {
        if (typeof value === 'object') return <MoneyCell inTable value={value} loading={loading} />;
        else return <span>{`${value}${typeof value === 'number' ? '%' : ''}`}</span>;
      },
      isPreviewCell: true,
    },
    {
      key: 'justActions',
      Cell: ({ original: { id } }) => (
        <>
          <ActionsButton
            tooltipId={`Edit__tooltip-${id}`}
            tooltipTextId="justEdit"
            edit
            onClickFunc={e => editBonusesClick(e, id)}>
            <EditIcon />
          </ActionsButton>
          <ActionsButton
            tooltipId={`Delete__tooltip-${id}`}
            tooltipTextId="justDelete"
            deleteType
            onClickFunc={e => deleteBonusesClick(e, id)}>
            <BasketIcon />
          </ActionsButton>
        </>
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => {
      history.push(`/backoffice/bonuses/${rowInfo.original.id}/edit`);
    },
  });
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="BonusManagementTable">
      <PaginationTable
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        loading={loading}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BonusManagementTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

BonusManagementTable.defaultProps = {
  loading: false,
  theme: {},
};

export default withTheme()(ModalHOC(BonusManagementTable));
export { BonusManagementTable };
