import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withTheme } from 'common/styling/theme';
import { ModalHOC } from 'common';
import { Collapsible, TableLoader } from 'common/components';
import { FormattedMessage } from 'react-intl';

import { getMyInvestStrategies, setInvestStrategyAction } from 'client/invest/_redux';
import InvestStrategiesTable from '../InvestStrategiesTable';

import { staticStyles, getDynamicStyles } from './style';

const InvestStrategies = ({
  accounts,
  myInvestStrategies,
  myInvestStrategiesAreLoaded,
  getMyInvestStrategies,
  setInvestStrategyAction,
  showModal,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [activeTab, setActiveTab] = useState('active');

  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => ({ page: 1, page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const getList = useCallback(
    (query, tab) => {
      getMyInvestStrategies({ isActive: tab === 'active' ? '1' : '0', ...query });
    },
    [getMyInvestStrategies]
  );

  const getTableList = useCallback(
    query => {
      getMyInvestStrategies({ isActive: activeTab === 'active' ? '1' : '0', ...query });
    },
    [getMyInvestStrategies, activeTab]
  );

  const handleOpenCollapse = () => {
    getList(query, activeTab);
  };

  const onChangeStrategyAction = (e, strategyId, action, isRollover) => {
    e.stopPropagation();

    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: action === 'close' ? 'rammConfirmStrategyActionClose' : 'rammConfirmStrategyAction',
        ...(action !== 'close' && {
          values: {
            action,
          },
        }),
      },
      description: {
        id: isRollover && action === 'close' ? 'investRolloversCloseWarning' : '',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => setInvestStrategyAction({ strategyId, action }),
        type: action === 'close' ? 'error' : '',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  const buttons = [
    {
      type: 'investments',
      textId: 'rammListOfInvestments',
    },
  ];

  const handleAction = ({ e, id, settings, action }) => {
    e.stopPropagation();

    switch (action) {
      case 'investments':
        history.push(`/invest/${id}`);
        break;
      case 'resume':
        onChangeStrategyAction(e, id, action);
        break;
      case 'close':
        onChangeStrategyAction(e, id, action, settings.rollovers);
        break;
      default:
        break;
    }
  };

  return (
    <div className="InvestStrategies">
      <Collapsible captionId="justStrategies" handleOpen={handleOpenCollapse}>
        <div className="InvestStrategies__tabs">
          <FormattedMessage id="justActive">
            {txt => (
              <button
                type="button"
                className={classNames('InvestStrategies__tab', {
                  active: activeTab === 'active',
                })}
                onClick={() => {
                  setActiveTab('active');
                  getList(query, 'active');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="justClosed">
            {txt => (
              <button
                type="button"
                className={classNames('InvestStrategies__tab', {
                  active: activeTab === 'closed',
                })}
                onClick={() => {
                  setActiveTab('closed');
                  getList(query, 'closed');
                }}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        {myInvestStrategiesAreLoaded ? (
          <InvestStrategiesTable
            buttons={buttons}
            activeTab={activeTab}
            data={myInvestStrategies}
            loading={!myInvestStrategiesAreLoaded}
            location={location}
            history={history}
            getList={getTableList}
            onRowClick={strategyId => history.push(`/invest/${strategyId}/`)}
            params={{
              search: query,
            }}
            accounts={accounts}
            onTableAction={handleAction}
          />
        ) : (
          <TableLoader />
        )}
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestStrategies.propTypes = {
  accounts: PropTypes.array.isRequired,
  myInvestStrategies: PropTypes.object.isRequired,
  myInvestStrategiesAreLoaded: PropTypes.bool.isRequired,
  getMyInvestStrategies: PropTypes.func.isRequired,
  setInvestStrategyAction: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

InvestStrategies.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  ModalHOC,
  connect(
    state => ({
      accounts: state.client.common.accounts,
      addons: state.interfaceConfig.addons,
      myInvestStrategies: state.client.invest.myInvestStrategies,
      myInvestStrategiesAreLoaded: state.client.invest.myInvestStrategiesAreLoaded,
    }),
    {
      getMyInvestStrategies: data => getMyInvestStrategies.request(data),
      setInvestStrategyAction: data => setInvestStrategyAction.request(data),
    }
  )
)(InvestStrategies);
export { InvestStrategies };
