import { watchers as commonWatchers } from './commonSagas';
import { watchers as usersWatchers } from '../users';
import { watchers as paymentsWatchers } from '../payments';
import { watchers as issuesWatchers } from '../support';
import { watchers as newsWatchers } from '../news';
import { watchers as partnershipWatchers } from '../partnership';
import { watchers as transfersWatchers } from '../transfers';
import { watchers as bonusesWatchers } from '../bonuses';
import { watchers as faqWatchers } from '../faq';
import { watchers as promoWatchers } from '../promo';
import { watchers as managersWatchers } from '../managers';
import { watchers as templatesWatchers } from '../templates';
import { watchers as statusesWatchers } from '../statuses';
import { watchers as investWatchers } from '../invest';

export default [
  ...commonWatchers,
  ...usersWatchers,
  ...paymentsWatchers,
  ...issuesWatchers,
  ...newsWatchers,
  ...partnershipWatchers,
  ...transfersWatchers,
  ...bonusesWatchers,
  ...faqWatchers,
  ...promoWatchers,
  ...managersWatchers,
  ...templatesWatchers,
  ...statusesWatchers,
  ...investWatchers,
];
