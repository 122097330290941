import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withTheme } from 'common/styling/theme';
import { getCookie } from 'common/utils/helpers';
import { PageTabs, TranslatedNavLink } from 'common/components';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { staticStyles, getDynamicStyles } from './style';
import RegistrationForm from '../_components/RegistrationForm';
import ThankForRegistration from '../_components/ThankForRegistration';

const RegistrationPage = ({ isEmailConfirmationEnabled, location, history, telegramAuthError, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const partnerCodeCookie = getCookie('partner_code');

  /* eslint-disable */
  const [isRegistered, setRegistered] = useState(false);
  const { partner_code, telegramAuth } = queryString.parse(location.search);
  const partnerCode = partner_code || localStorage.getItem('partnerCode') || partnerCodeCookie;
  const isTelegramAuth = telegramAuth === 'true' || telegramAuthError;
  const telegramDataJson = sessionStorage.getItem('telegramData');
  const telegramData = JSON.parse(telegramDataJson || '{}');
  const initialValues =
    telegramData && isTelegramAuth
      ? {
          firstName: telegramData.telegramFirstName ?? '',
          lastName: telegramData.telegramLastName ?? '',
          partnerCode: partnerCode,
        }
      : { partnerCode: partnerCode };
  /* eslint-enable */

  return (
    <>
      <PageTabs forAuth>
        <TranslatedNavLink
          navLinkId="registration-page-nav-to-login-page"
          id="authLogin"
          to={`/auth/login${isTelegramAuth ? `?telegramAuth=true` : ''}`}
        />
        <TranslatedNavLink
          navLinkId="registration-page-nav-to-registration-page-active"
          id="authRegister"
          to="/auth/register"
        />
      </PageTabs>
      {isTelegramAuth && !isRegistered && (
        <div className="Auth__message">
          <FormattedMessage id="telegramRegisterMessage">{txt => <p>{txt}</p>}</FormattedMessage>
        </div>
      )}
      {isEmailConfirmationEnabled && isRegistered ? (
        <ThankForRegistration />
      ) : (
        <RegistrationForm
          initialValues={initialValues}
          displayThankBlock={() => setRegistered(true)}
          isEmailConfirmationEnabled={isEmailConfirmationEnabled}
          history={history}
          isTelegramAuth={isTelegramAuth}
          telegramData={telegramData}
        />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </>
  );
};

RegistrationPage.propTypes = {
  isEmailConfirmationEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  telegramAuthError: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

RegistrationPage.defaultProps = {
  location: {},
  theme: {},
};
export default compose(
  withRouter,
  withTheme(),
  connect(
    state => ({
      isEmailConfirmationEnabled: state.interfaceConfig.emailConfirmation.enabled,
      telegramAuthError: state.auth.registration.telegramAuthError,
    }),
    null
  )
)(RegistrationPage);
export { RegistrationPage };
